import Inventory from './inventory/Inventory';
import Diagnostics from './diagnostics/Diagnostics';
import Jobs from './jobs/Jobs';
import Settings from './settings/Settings';
// import SEMDisplayReporting from './jobs/SEMDisplayReporting';

const RouteTree = [
        {
        path: '/config/:id?',
        name: 'config',
        label: 'Config',
        component: Settings,
        subSections: [
            {
                label: 'Clients',
                path: '/clients'
            },
            {
                label: 'SEIM',
                path: '/seim'
            },
        ]
    },
    {
        path: '/inventory/:id?',
        name: 'inventory',
        label: 'Inventory',
        component: Inventory,
        subSections: [

            {
                label: 'Run',
                path: '/run',
            },
            {
                label: 'Scrapers',
                path: '/scrapers',
            },
            {
                label: 'SEIM Feeds',
                path: '/seim-feeds',
            },
            {
                label: 'Dynamic Feeds',
                path: '/dynamic-feeds',
            },
            {
                label: 'SEIM Templates',
                path: '/seim templates',
            },
            {
                label: 'SEIM Logs',
                path: '/seim logs',
            }
        ]
    },
    {
        path: '/diagnostics/:id?',
        name: 'diagnostics',
        label: 'Diagnostics',
        component: Diagnostics,
        subSections: [
            // {
            //     label: 'Tests',
            //     path: '/tests',
            // },
            {
                label: 'Model Year Checker',
                path: '/model-year-checker',
            },
            {
                label: 'Images',
                path: '/images',
            },
            {
                label: 'Speed Test',
                path: '/speed-test',
            }
        ]
    },
    {
        path: '/jobs/:id?',
        name: 'jobs',
        label: 'Jobs',
        component: Jobs,
        subSections: [
            {
                label: 'All',
                path: '/all',
            },
            {
                label: 'Scrapers',
                path: '/scrapers'
            },
            {
                label: 'Dynamic',
                path: '/dynamic'
            },
            {
                label: 'SEIM',
                path: '/seim'
            }
        ]
    }
];

export default RouteTree;
