import React from 'react';
import ModelYears from './ModelYears';
// import Images from './Images';
// import Tests from './Tests';
import SpeedTest from './SpeedTest';

class Diagnostics extends React.Component {

    /**
     *
     * @returns {*}
     */
    render() {
        if (this.props.subSection === 'Model Year Checker') {
            return (
                <ModelYears {...this.props} />
            );
        }
        // else if (this.props.subSection === 'Images') {
        //     return (
        //         <Images {...this.props} />
        //     );
        // }
        // else if (this.props.subSection === 'Tests') {
        //     return (
        //         <Tests {...this.props} />
        //     );
        // }
        else if (this.props.subSection === 'Speed Test') {
            return (
                <SpeedTest {...this.props} />
            );
        }
        else {
            return null;
        }
    }
}

export default Diagnostics;