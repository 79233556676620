import React from 'react';
import SelectStyles from '../../utils/SelectStyles';

import update from 'immutability-helper';

import {
    MenuItem,
    Select,
    Card
} from '@mui/material';

class Logs extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.sharedMethods = {
            handleInputChange: (...args) => {
                this.setState(this.props.handleInputChange(this.state, args));
            }
        };

        this.state = {
            script: 'Select a script',
            scripts: [],
            google: {
                dateTime: null,
                logs: [],
                failedAds: [],
                scrapers: []
            },
            bing: {
                dateTime: null,
                logs: [],
                failedAds: [],
                scrapers: [],
            }
        }
    }


    /**
     *
     */
    componentDidMount() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'script/index'
        })
            .then(data => {

                this.props.stopLoading();
                this.setState({
                    scripts: data
                });
            });
    }


    /**
     *
     * @param script
     */
    getScriptLogs(script) {

        this.props.startLoading();

        // GOOGLE
        this.props.API({
            service: 'management',
            route: 'script/show/'+script.feed.tab+'_GOOGLE'
        })
            .then(data => {

                this.props.stopLoading();
                this.setState(update(this.state, {
                    google: {
                        logs: { $set: JSON.parse(data.logs) },
                        failedAds: { $set: JSON.parse(data.failedAds) },
                        dateTime: { $set: data.dateTime },
                        key: { $set: data.key }
                    }}
                ));
            });

        // BING
        this.props.API({
            service: 'management',
            route: 'script/show/'+script.feed.tab+'_BING'
        })
            .then(data => {

                if (data) {

                    this.setState(update(this.state, {
                        bing: {
                            logs: { $set: JSON.parse(data.logs) },
                            failedAds: { $set: JSON.parse(data.failedAds) },
                            dateTime: { $set: data.dateTime },
                            key: { $set: data.key }
                        }}
                    ));
                }
            });
    }


    /**
     *
     * @param logs
     * @param failedAds
     * @param heading
     * @param dateTime
     * @returns {*[]}
     */
    renderLogs(logs, failedAds, heading, dateTime) {

        const categories = {
            pricesUpdated: 'Updated Price',
            enabledAds: 'Enabled Ad',
            newKeywords: 'Created Keyword',
            milesUpdated: 'Updated Mileage'
        };

        return [
            <h1 key="heading">{heading}</h1>,
            <h5 key="dateTime">Ran on {dateTime}</h5>,
            <br key="break"/>,
            failedAds.length > 0 ? [
                <h5 key="failed">Failed</h5>,
                failedAds.map((failed, i) => {
                    return (
                        <div key={i}>
                            {JSON.stringify(failed)}
                        </div>
                    );
                })
            ] : null,
            <br key="break2"/>,
            logs.map((log, i) => {

                let milesPriceVal;
                if ('miles' in log) {
                    milesPriceVal = log.miles;
                }
                else if ('price' in log) {
                    milesPriceVal = log.price;
                }

                return (
                    <Card key={i} classes={{
                        root: 'card'
                    }}>
                        <h6 className='adGroupHeading'>{log.adGroup}</h6>
                        <h6>{
                            log.category in categories
                                ? categories[log.category] : log.category}{milesPriceVal
                                    ? ' to '+milesPriceVal : null
                        }</h6>
                        {'entity' in log ? (
                            <div>
                                {JSON.stringify(log.entity)}
                            </div>
                        ) : null}
                    </Card>
                );
            })
        ];
    }


    /**
     *
     * @returns {*[]}
     */
    render() {

        return [
            <Select
                styles={SelectStyles}
                MenuProps={{
                    PaperProps: {
                        style: {
                            transform: 'translate3d(0, 0, 0)'
                        }
                    }
                }}
                key="select"
                classes={{
                    root: 'script-select'
                }}
                autoWidth
                value={this.state.script}
                onChange={(e) => {
                    this.sharedMethods.handleInputChange(e, 'script');
                    this.getScriptLogs(this.state.scripts[e.target.value]);
                }} >

                <MenuItem value='Select a script'>Select an SEIM campaign</MenuItem>

                {this.state.scripts.map((script, i) => {
                    if (script.enabled) {
                        return (
                            <MenuItem
                                key={i}
                                value={i}>

                                {script.client.name} | {script.campaign_name}
                            </MenuItem>
                        );
                    }
                    else return null;
                })}
            </Select>,
            <div id="logs" key="logs" className="d-flex">
                <div className="mr-5">
                    {this.state.google.logs.length > 0 ? (
                        this.renderLogs(this.state.google.logs, this.state.google.failedAds, 'Google', this.state.google.dateTime)
                    ) : null}
                </div>
                <div className="mr-5">
                    {this.state.bing.logs.length > 0 ? (
                        this.renderLogs(this.state.bing.logs, this.state.bing.failedAds, 'Bing', this.state.bing.dateTime)
                    ) : null}
                </div>
            </div>
        ];
    }
}

export default Logs;