import React from 'react';
import FormDialog from "../shared/FormDialog";
import ConfirmDelete from "../shared/ConfirmDelete";
import FormattedInput from '../shared/FormattedInput';
import update from 'immutability-helper';

import {
    List,
    ListItem,
    ListItemText,
    Input,
    InputLabel,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Fab,
    Icon,
    Tabs,
    Tab,
    Divider
} from '@mui/material';

class SEIM extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.sharedMethods = {
            handleInputChange: (...args) => {
                this.setState(this.props.handleInputChange(this.state, args));
            },
            confirmDelete: (...args) => {
                this.setState(this.props.confirmDelete(this.state, args));
            },
            handleClickOpen: (...args) => {
                this.setState(this.props.handleClickOpen(args));
            },
            handleRequestClose: (...args) => {
                this.setState(this.props.handleRequestClose(this.state, args));
            },
            validate: (...args) => {
                const results = this.props.validate(this.state, args);
                this.setState(results.state);
                return results.validated;
            },
            generateInputState: (...args) => {
                return this.props.generateInputState(args);
            },
            generateErrorState: (...args) => {
                return this.props.generateErrorState(args);
            }
        };

        this.form = {
            rows: [
                {
                    inputs: [
                        {
                            name: 'Keyword Text',
                            key: 'text',
                            default: '',
                            type: 'text',
                            error: false,
                            errorText: 'Keyword text cannot be blank.'
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Match Type',
                            key: 'type',
                            default: 0,
                            label: 'Match Type',
                            noValidate: true,
                            type: 'select',
                            options: [
                                {
                                    value: 0,
                                    name: 'broad'
                                },
                                {
                                    value: 1,
                                    name: 'phrase'
                                },
                                {
                                    value: 2,
                                    name: 'exact'
                                }
                            ]
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Spanish',
                            key: 'spanish',
                            default: false,
                            noValidate: true,
                            type: 'checkbox'
                        },
                        {
                            name: 'CPO',
                            key: 'cpo',
                            default: false,
                            noValidate: true,
                            type: 'checkbox'
                        }
                    ]
                }
            ]
        };

        this.state = {
            inputs: this.sharedMethods.generateInputState(this.form),
            errors: this.sharedMethods.generateErrorState(this.form),
            filter: 'english',
            seim_keyword_cpc: null,
            keywords: [],
            open: false,
            edit: false,
            editId : null,
            deleteIds: null,
            confirmDeleteOpen: false
        };
    }


    /**
     *
     */
    componentDidMount() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'settings/index'
        })
            .then(data => {
                this.setState({
                    seim_keyword_cpc: data[0].seim_keyword_cpc
                });
            });

        this.props.API({
            service: 'management',
            route: 'settings/keyword/index'
        })
            .then(data => {

                this.props.stopLoading();
                this.setState({
                    keywords: data
                });
            });
    }


    /**
     *
     */
    submitCPC() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'settings/seim/update',
            data: {
                seim_keyword_cpc: this.state.seim_keyword_cpc
            }
        })
            .then(data => {

                this.props.stopLoading();
                this.setState({
                    seim_keyword_cpc: data[0].seim_keyword_cpc
                });
            });
    }


    /**
     *
     */
    submitKeyword() {

        const inputs = this.state.inputs;

        if (
            'type' in inputs
            && typeof inputs.type === 'object'
            && 'value' in inputs.type
        ) {
            inputs.type = inputs.type.value;
        }

        const send = () => {

            if (this.sharedMethods.validate(this.form)) {

                this.props.startLoading();

                const url = this.state.edit ? 'settings/keyword/update' : 'settings/keyword/store';

                const inputs = {
                    text: this.state.inputs.text,
                    type: this.form.rows[1].inputs[0].options[this.state.inputs.type].name,
                    spanish: this.state.inputs.spanish,
                    cpo: this.state.inputs.cpo
                };

                let payLoad;
                if (this.state.edit) {
                    payLoad = {
                        inputs: inputs,
                        id: this.state.editId
                    };
                }
                else {
                    payLoad = inputs;
                }

                this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                );

                this.props.API({
                    service: 'management',
                    route: url,
                    data: payLoad
                })
                    .then(data => {

                        this.props.stopLoading();
                        this.setState({
                            keywords: data
                        })
                    });
            }

        }

        this.setState({
            inputs: inputs
        }, send );

    }


    /**
     *
     * @param id
     * @param index
     */
    deleteKeyword(id, index) {

        this.props.API({
            service: 'management',
            route: 'settings/keyword/destroy/'+id
        })
            .then(data => {

                this.props.stopLoading();
                this.setState(update(this.state, {
                    keywords: {$set: data },
                    confirmDeleteOpen: {$set: false }
                }));
            });
    }


    /**
     *
     * @param keyword
     * @param id
     */
    editKeyword(keyword, id) {

        const option = this.form.rows[1].inputs[0].options.find((option) => {
            return option.name === keyword.type;
        });

        const inputs = {
            text: keyword.text,
            type: option.value,
            spanish: keyword.spanish,
            cpo: keyword.cpo
        };

        this.sharedMethods.handleClickOpen(update(this.state, {
            inputs: {$set: inputs},
            edit: {$set: true },
            editId: {$set: id }
        }));
    }


    /**
     *
     * @param inputName
     * @param inputKey
     * @param type
     * @returns {*}
     */
    renderTextField(inputName, inputKey, type) {

        return (
            <FormControl classes={{
                root: 'text-field'
            }}>
                <InputLabel>
                    {inputName}
                </InputLabel>

                <Input
                    onChange={(e) => this.sharedMethods.handleInputChange(e, inputKey, undefined, undefined, true)}
                    value={this.state[inputKey] ? this.state[inputKey] : ''}
                    inputProps={{
                        'data-currency': type === 'currency',
                        'data-percent': type === 'percent'
                    }}
                    inputComponent={FormattedInput}
                />
            </FormControl>
        );
    }


    /**
     *
     * @param e
     * @param value
     */
    handleFilterChange(e, value) {
        this.setState({
            filter: value
        });
    }


    /**
     *
     * @returns {*[]}
     */
    render() {

        return [
            <h3 key="heading">SEIM Settings</h3>,
            <div key="seim-settings" id="seim-settings">
                <div className="d-flex top-row">
                    {this.renderTextField('Default SEIM Keyword CPC', 'seim_keyword_cpc', 'currency')}
                    <Button
                        variant='contained'
                        onClick={() => this.submitCPC()}
                        classes={{
                            root: 'submit'
                        }} >

                        <Icon>done</Icon>
                        Save
                    </Button>
                </div>
                <div id="keywords">
                    <h4 key="heading">Keywords</h4>
                    <Fab
                        size='small'
                        key="add"
                        onClick={() => this.sharedMethods.handleClickOpen(this.state)}
                        classes={{
                            root: 'add-button'
                        }} >

                        <Icon>add</Icon>
                    </Fab>
                    <Tabs
                        value={this.state.filter}
                        onChange={(e, value) => this.handleFilterChange(e, value)}
                        indicatorColor="primary"
                        textColor="primary" >

                        <Tab value="english" label="English" />
                        <Tab value="spanish" label="Spanish" />
                        <Tab value="cpo" label="CPO" />
                    </Tabs>
                    <Divider light />
                    <List>
                        {this.state.keywords.filter((kw) => {
                            if (this.state.filter === 'english' && !kw.spanish && !kw.cpo) {
                                return true;
                            }
                            else if (this.state.filter === 'spanish' && kw.spanish && !kw.cpo) {
                                return true;
                            }
                            else if (this.state.filter === 'cpo' && kw.cpo) {
                                return true;
                            }
                            else return false;
                        }).map((kw, i) => {
                            return (
                                <ListItem key={i} classes={{
                                    root: 'keyword-row'
                                }}>
                                    <ListItemText
                                        primary={kw.text}
                                        classes={{
                                            root: 'keyword-text'
                                        }}
                                    />

                                    <ListItemText
                                        primary={kw.type.toUpperCase()}
                                        classes={{
                                            root: 'keyword-type-text'
                                        }}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled
                                                checked={kw.spanish === 1 || kw.spanish === true}
                                                color="primary"
                                            />
                                        }
                                        label="Spanish"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled
                                                checked={kw.cpo === 1 || kw.cpo === true}
                                                color="primary"
                                            />
                                        }
                                        label="CPO"
                                    />

                                    <Button
                                        variant='contained'
                                        onClick={() => this.editKeyword(kw, kw.id)}
                                        classes={{
                                            root: 'inventory-edit'
                                        }} >

                                        <Icon>mode_edit</Icon>
                                        Edit
                                    </Button>

                                    <Button
                                        variant='contained'
                                        onClick={() => this.sharedMethods.confirmDelete(kw.id, i)}
                                        classes={{
                                            root: 'inventory-delete'
                                        }} >

                                        <Icon>delete</Icon>
                                        Delete
                                    </Button>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </div>,
            <FormDialog
                id="keyword-dialog"
                key="dialog"
                entityName="Keyword"
                open={this.state.open}
                edit={this.state.edit}
                errors={this.state.errors}
                inputs={this.state.inputs}
                form={this.form}
                submit={() => this.submitKeyword()}
                handleInputChange={(e, key) => this.sharedMethods.handleInputChange(e, key, 'inputs')}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                )}
            />,
            <ConfirmDelete
                key="delete"
                confirmDeleteOpen={this.state.confirmDeleteOpen}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                )}
                deleteIds={this.state.deleteIds}
                delete={(id, index) => this.deleteKeyword(id, index)}
            />
        ];
    }
}

export default SEIM;