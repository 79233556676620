import React, { Component } from 'react';
import classNames from 'classnames';

class Loading extends Component {

    /**
     *
     * @returns {*}
     */
    render() {

        return (
            <div className={classNames('d-flex','justify-content-center','align-items-center','loading-wrapper')}>
                <div className='loading-icon'>
                    <span className={classNames('dot','dot-1')}></span>
                    <span className={classNames('dot','dot-2')}></span>
                    <span className={classNames('dot','dot-3')}></span>
                    <span className={classNames('dot','dot-4')}></span>
                </div>
            </div>
        )
    }
}

export default Loading;