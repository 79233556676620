import React, { Fragment } from 'react';
import {Button, Paper, Divider} from '@mui/material';
import update from 'immutability-helper';
import Select from 'react-select';
import SelectStyles from '../../utils/SelectStyles';

class ModelYears extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.nonApplicableClients = [
            'Doug\'s Mazda',
            'Durant Auto Group',
            'Jerry\'s Preowned',
            'Jerry\'s Texas',
            'Legend Auto Sales'
        ];

        this.state = {
            selected: [],
            clients: [],
            results: [],
            last_scraped: ''
        };
    }


    /**
     *
     */
    componentDidMount() {
        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'client/scraper/index'
        })
            .then(data => {
                this.props.stopLoading();
                this.setState({
                    clients: data
                });
            });
    }


    /**
     *
     * @param e
     */
    handleChange(e) {

        this.setState(update( this.state, { selected: { $set: e }}));

    }


    /**
     *
     */
    submitModelYear() {
        if (this.state.selected.indexOf('Select one or multiple clients') === -1) {

            this.setState({
                results: [],
                last_scraped: ''
            });

            this.props.startLoading();

            this.props.API({
                service: 'management',
                route: 'model-year/run',
                data: {
                    clientNames: this.state.selected.map( client => typeof client === 'object' && 'value' in client ? client.value : client )
                }
            })
                .then(data => {

                    this.props.stopLoading();

                    if (data) {
                        this.setState({
                            results: data
                        });
                    }
                    else {
                        window.alert('Something went wrong');
                    }

                });
        }
    }

        /**
     *
     */
    selectAllClients() {

        // [
        //     {
        //         "label": "Bill DeLuca Chevrolet",
        //         "value": "Bill DeLuca Chevrolet"
        //     },
        //     ...
        // ]
        this.setState({
            selected: this.state.clients
                .map(
                    ({name: label}) => ({
                        label,
                        value: label
                    })
                )
        });

    }

    generateDaysAgo(result) {

        const hasDate = result.lastScraped && result.lastScraped;

        let daysAgo = 0;

        if (hasDate) {

            const date1 = new Date(Date.parse(result.lastScraped)).setHours(0,0,0,0);
            const date2 = new Date().setHours(0,0,0,0);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            // console.log(diffTime + " milliseconds");
            daysAgo = diffDays;

        }

        let color = 'var(--blue)';
        let fontSize = '18px';

        switch(daysAgo) {
            case 0:
                break;
            case 1:
            case 2:
                fontSize = '21px';
                color = 'var(--orange)';
                break;
            default:
                fontSize = '26px';
                color = 'var(--red)';
        }

        const out = [

            hasDate
                ? <p
                    key="last_scraped"
                    style={{marginTop: '10px', fontSize: '18px', color: 'var(--blue)'}}
                >
                    <span>Last Scraped:&nbsp;</span>
                    {
                        new Intl.DateTimeFormat(
                            'en-US',
                            { dateStyle: 'full', timeStyle: 'medium' }
                        )
                            .format(
                                new Date(
                                    result.lastScraped
                                )
                            )
                    }
                </p>
                : null,

            hasDate
                ? <p
                    key="last_scraped_warning"
                    style={{
                        marginTop: '10px',
                        fontSize: fontSize,
                        color: color
                    }}
                >{
                    daysAgo === 0 ? 'Scraped Today' : 'scraped: ' + daysAgo + ' day(s) ago'
                }</p>
                : null

        ];

        return out;

    }

    /**
     *
     * @returns {*}
     */
    render() {

        return (
            <div id="model-year">
                <div key="heading" className="heading d-flex align-items-center">

                    <h2 className="text-center title">
                        Model Year Checker
                    </h2>

                    <div style={{minWidth: '300px'}}>
                        <Select
                            styles={SelectStyles}
                            key='model-year-select'
                            isMulti={true}
                            isSearchable={true}
                            placeholder='Select multiple clients...'
                            value={this.state.selected}
                            onChange={ (e) => this.handleChange(e) }
                            noOptionsMessage={() => {
                                if ( this.state.clients.length ) {
                                    return 'No Matches!';
                                } else {
                                    return 'Loading Clients...';
                                }
                            }}
                            options={
                                this.state.clients.map((client, i) => {
                                    if (this.nonApplicableClients.indexOf(client.name) === -1 && client.adwords_sem_account_id) {
                                        return ({
                                            label: client.name,
                                            value: client.name
                                        });
                                    }
                                    return null;
                                }).filter( client => {
                                    if ( client && 'value' in client ) {
                                        return client;
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        />
                    </div>

                </div>
                <Button
                    variant="contained"
                    classes={{
                        root: 'run-model-year'
                    }}
                    onClick={() => this.submitModelYear()} >

                    Run Model Year Checker
                </Button>

                <br />
                <Button
                    variant="contained"
                    classes={{
                        root: 'run-model-year'
                    }}
                    onClick={() => this.selectAllClients()} >

                    Select All Clients
                </Button>

                {
                    this.state.results.map( result =>
                        <Paper
                            elevation={10}
                            key={result.googleAdsAccountId}
                            classes={
                                {root: 'paper'}
                            }
                        >

                            <h4>
                                {result.clientName ? `${result.clientName}, ` : ''}
                                {result.clientAcronym ? `${result.clientAcronym}, ` : ''}
                                {result.scraperMapKey ?? ''}
                            </h4>

                            {this.generateDaysAgo(result)}

                            <Divider light/>

                            <div className="in">
                                <h5>Google Ads: {result.googleAdsAccountId ?? ''}</h5>
                                {
                                    Object.keys(result.errors ?? {}).length
                                        ? Object.entries(result.errors).map(
                                            ([hash, error]) =>
                                                <div key={hash}>{error}</div>
                                        )
                                        : <h5>Success, no issues found! nice job cleaning up!</h5>
                                }
                            </div>
                        </Paper>
                    )
                }

            </div>
        );
    }
}

export default ModelYears;