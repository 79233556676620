import React from 'react';
import SideBar from './shared/SideBar';
import {Tab, Tabs, Icon, Divider, LinearProgress } from '@mui/material';

import classNames from 'classnames';
import {
    handleInputChange,
    confirmDelete,
    handleClickOpen,
    handleRequestClose,
    generateInputState,
    generateErrorState,
    validate
} from './shared/Methods';

import {
    Link
} from "react-router-dom";

class Content extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {

        super(props);

        this.tabClass = {
            root: 'nav-tab'
        };

        this.commonProps = {
            API: props.API,
            startLoading: props.startLoading,
            stopLoading: props.stopLoading,
            handleInputChange: handleInputChange,
            confirmDelete: confirmDelete,
            handleClickOpen: handleClickOpen,
            handleRequestClose: handleRequestClose,
            generateInputState : generateInputState,
            generateErrorState: generateErrorState,
            validate: validate,
        };

        this.state = {
            subSection: {}
        };

        this.defaultSubSection = {
            label: null,
            path: '/'
        };
        // console.log('in!');

    }

    componentDidMount() {
        // console.log('mount!');
        this.doSubSection(
            this.getPathname()
        );
    }

    componentDidUpdate(prevProps) {
        const pathname = this.getPathname();
        if(
            this.getPathname(
                prevProps
            )
                !== pathname
        ) this.doSubSection(pathname);

    }

    getPathname = ({location}=this.props) => location?.pathname.split('/')[2]
        ?? '';

    doSubSection(
        pathname
    ) {

        let {subSections} = this.props.route;

        const subSection =
            (
                subSections?.length
                    ? subSections
                    : []
            )
                .filter( ({path:p}) =>
                    p === `/${pathname}`
                )[0]
            ?? this.defaultSubSection;

        if(
            this.state.subSection
                !== subSection
        )
            this.setState({
                subSection
            });

    }

    /**
     *
     * @returns {*}
     */
    render() {

        return (
            <div className={`${this.state.selected === 'gtm' ? "background" : ""} container`}>

                {/*TOP NAVIGATIO window.history.replaceState()N TABS*/}

                <div id="top-nav">
                    <Tabs
                        value={this.props.route.name}
                        onChange={(e, value) => {
                            this.props.stopLoading();
                            this.setState({
                                selected: value
                            });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth" >
                        {
                            this.props.routes.map(r=>(
                                <Tab
                                    disabled={this.props.loading}
                                    value={r.name}
                                    key={r.name+'-tab'}
                                    classes={this.tabClass}
                                    label={ r.label==='Config'? null : r.label}
                                    to={`/${r.path.split('/')[1]}`}
                                    component={Link}
                                    icon={r.label==='Config'?<Icon>settings</Icon>:null}
                                />
                            ))
                        }
                    </Tabs>
                    <Divider light classes={{
                        root: 'divider'
                    }} />
                </div>

                <div key="progress" id="progress-bar">
                            {this.props.loading ? (
                                <LinearProgress
                                    classes={{
                                        root: 'bar'
                                    }}
                                />
                            ) : (
                                null
                            )}
                        </div>

                {/*RENDER MAIN CONTENT*/}
                <div className="management-main">
                    {this.props.route.subSections && this.props.route.subSections.length > 0 ? (
                        <SideBar
                            {...this.commonProps}
                            loading={this.props.loading}
                            selected={this.props.route.name}
                            subSections={this.props.route.subSections}
                            subSection={this.state.subSection}
                        />
                        ) : (
                            null
                        )
                    }
                    <div className={classNames("content", {
                        'no-sidebar': this.props.route.subSections && this.props.route.subSections.length === 0
                    })}>
                        <div id={this.props.route.name}>
                            <this.props.route.component {...this.commonProps} subSection={this.state.subSection.label}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;