import React from 'react';
import update from 'immutability-helper';
import FormDialog from "../shared/FormDialog";
import ConfirmDelete from "../shared/ConfirmDelete";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
    Fab,
    Icon
} from '@mui/material';

class Scrapers extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.sharedMethods = {
            handleInputChange: (...args) => {
                this.setState(this.props.handleInputChange(this.state, args));
            },
            confirmDelete: (...args) => {
                this.setState(this.props.confirmDelete(this.state, args));
            },
            handleClickOpen: (...args) => {
                this.setState(this.props.handleClickOpen(args));
            },
            handleRequestClose: (...args) => {
                this.setState(this.props.handleRequestClose(this.state, args));
            },
            validate: (...args) => {
                const results = this.props.validate(this.state, args);
                this.setState(results.state);
                return results.validated;
            },
            generateInputState: (...args) => {
                return this.props.generateInputState(args);
            },
            generateErrorState: (...args) => {
                return this.props.generateErrorState(args);
            }
        };

        this.form = {
            rows: [
                {
                    inputs: [
                        {
                            name: 'Client',
                            key: 'client_id',
                            type: 'select',
                            nullable: false,
                            default: 'Select a client',
                            errorText: 'Client field cannot be empty.',
                            clients: true
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Client Map Key',
                            key: 'map_key',
                            type: 'text',
                            error: false,
                            nullable: false,
                            default: '',
                            errorText: 'Client map key cannot be empty.'
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Data URL',
                            key: 'data_url',
                            type: 'text',
                            error: false,
                            nullable: true,
                            default: '',
                            errorText: 'Client Data URL is wrong.'
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Enabled',
                            key: 'enabled',
                            type: 'select',
                            noValidate: true,
                            default: 1,
                            nullable: true,
                            options: [
                                {
                                    name: 'Enabled',
                                    value: 1
                                },
                                {
                                    name: 'Paused',
                                    value: 0
                                }
                            ]
                        }
                    ]
                }
            ]
        };

        this.state = {
            inputs: this.sharedMethods.generateInputState(this.form),
            errors: this.sharedMethods.generateErrorState(this.form),
            scrapers: [],
            clients: [],
            open: false,
            edit: false,
            editId: null,
            deleteIds: null,
            confirmDeleteOpen: false
        };
    }


    /**
     *
     */
    componentDidMount() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'scraper/index'
        })
            .then(data => {
                this.setState({
                    scrapers: data
                });
            });

        this.props.API({
            service: 'management',
            route: 'client/index'
        })
            .then(data => {

                this.props.stopLoading();
                this.setState({
                    clients: data
                });
            });
    }


    /**
     *
     */
    submitScraper() {

        let inputs = this.state.inputs;
        let keys = [ 'client_id', 'enabled', 'scraper_id', 'data_url' ];

        for( let key of keys ) {
            if (
                inputs
                && inputs[key]
                && typeof inputs[key] === 'object'
                && 'value' in inputs[key]
            ) {
                inputs[key] = inputs[key].value;
            }
        }

        const send = () => {
            if (this.sharedMethods.validate(this.form)) {

                this.props.startLoading();
                const url = this.state.edit ? 'scraper/update' : 'scraper/store';

                let payLoad;
                if (this.state.edit) {
                    payLoad = {
                        inputs: this.state.inputs,
                        id: this.state.editId
                    };
                }
                else {
                    payLoad = this.state.inputs;
                }

                this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                );

                this.props.API({
                    service: 'management',
                    route: url,
                    data: payLoad
                })
                    .then(data => {

                        this.props.stopLoading();
                        this.setState({
                            scrapers: data
                        })
                    });
            }
        };

        this.setState({
            inputs: inputs
        }, send );

    }


    /**
     *
     * @param id
     * @param index
     */
    deleteScraper(id, index) {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'scraper/destroy/'+id
        })
            .then(data => {

                this.props.stopLoading();

                this.setState(update(this.state, {
                    scrapers: {$splice: [[index, 1]] },
                    confirmDeleteOpen: {$set: false }
                }));
            });
    }


    /**
     *
     * @param scraper
     * @param id
     */
    editScraper(scraper, id) {

        this.sharedMethods.handleClickOpen(update(this.state, {
            inputs: {$set: {
                client_id: scraper.client_id,
                map_key: scraper.map_key,
                data_url: scraper.data_url,
                enabled: scraper.enabled
            }},
            edit: {$set: true },
            editId: {$set: id }
        }));
    }


    /**
     *
     * @returns {*[]}
     */
    render() {
        return [
            <Fab
                size='small'
                key="add"
                onClick={() => this.sharedMethods.handleClickOpen(this.state)}
                classes={{
                    root: 'add-button'
                }} >

                <Icon>add</Icon>
            </Fab>,
            <List key="list" id="scrapers" classes={{
                root: 'active-list'
            }}>
                <ListItem id="first-row" classes={{
                    root: 'd-flex activation-row'
                }}>

                    <ListItemText
                        primary="Enabled"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                    <ListItemText
                        primary="Client"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                    <ListItemText
                        primary="Client Map Key"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                    <ListItemText
                        primary="Client Data URL"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                </ListItem>


                {this.state.scrapers.map((scraper, s) => {

                    return [
                        <Divider key="divider" light/>,
                        <ListItem key={s} classes={{
                            root: 'd-flex activation-row'
                        }}>

                            <ListItemIcon
                                classes={{
                                    root: 'list-item enabled-button'
                                }}>

                                {!scraper.enabled ? (
                                    <Icon>
                                        pause
                                    </Icon>
                                ) : (
                                    <Icon classes={{
                                        root: 'active-icon'
                                    }}>
                                        fiber_manual_record
                                    </Icon>
                                )}
                            </ListItemIcon>

                            <ListItemText
                                classes={{
                                    root: 'list-item'
                                }}
                                primary={scraper.client.name}
                            />

                            <ListItemText
                                classes={{
                                    root: 'short-list-item'
                                }}
                                primary={scraper.map_key}
                            />

                            <ListItemText
                                classes={{
                                    root: 'long-list-item'
                                }}
                                primary={scraper.data_url}
                            />

                            <Button
                                variant='contained'
                                onClick={() => this.editScraper(scraper, scraper.id)}
                                classes={{
                                    root: 'inventory-edit'
                                }} >

                                <Icon>mode_edit</Icon>
                                Edit
                            </Button>

                            <Button
                                variant='contained'
                                onClick={() => this.sharedMethods.confirmDelete(scraper.id, s)}
                                classes={{
                                    root: 'inventory-delete'
                                }} >

                                <Icon>delete</Icon>
                                Delete
                            </Button>

                        </ListItem>
                    ];
                })}
            </List>,
            <FormDialog
                key="dialog"
                entityName="Scraper"
                open={this.state.open}
                edit={this.state.edit}
                errors={this.state.errors}
                inputs={this.state.inputs}
                clients={this.state.clients}
                form={this.form}
                submit={() => this.submitScraper()}
                handleInputChange={(e, key) => this.sharedMethods.handleInputChange(e, key, 'inputs')}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                )}
            />,
            <ConfirmDelete
                key="delete"
                confirmDeleteOpen={this.state.confirmDeleteOpen}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form),
                    this.sharedMethods.generateErrorState(this.form)
                )}
                deleteIds={this.state.deleteIds}
                delete={(id, index) => this.deleteScraper(id, index)}
            />
        ];
    }
}

export default Scrapers;