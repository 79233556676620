import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions
} from '@mui/material';

class ConfirmDelete extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.confirmDeleteOpen}
                onClose={() => this.props.handleRequestClose()}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => this.props.handleRequestClose()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => this.props.delete(this.props.deleteIds.id, this.props.deleteIds.index)} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmDelete;