import React from 'react';
import Job from './Job';
import Paper from '@mui/material/Paper';

class Jobs extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {

        super(props);

        // this.intervalTimeout = null;

        this.state = {
            jobs: {
                'active': [],
                'queued': [],
                'failed': []
            }
        };

    }

    /**
     *
     */
    getJobs() {

        console.log('get jobs');
        this.setState({'jobs': null});
        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'jobs/index'
        })
            .then(data => {
                if (data) {

                    // if (this.intervalTimeout) {
                    //     clearInterval(this.intervalTimeout);
                    // }
                    // console.log( data );
                    const jobs = {
                        active: data.jobs
                            .filter(job => job.attempts > 0),
                        queued: [
                                ...data.delayed_jobs,
                                ...data.jobs.filter(job => job.attempts === 0)
                            ],
                        failed: data.failed_jobs
                    };

                    let changed = true;

                    if (this.state.jobs) {
                        changed = (
                            JSON.stringify(jobs.active) !== JSON.stringify(this.state.jobs.active) ||
                            JSON.stringify(jobs.queued) !== JSON.stringify(this.state.jobs.queued) ||
                            JSON.stringify(jobs.failed) !== JSON.stringify(this.state.jobs.failed)
                        );
                    }

                    if (changed) {
                        this.setState({
                            jobs: jobs
                        } );
                    }

                    // this.intervalTimeout = setInterval( this.getJobs, 2500);

                    this.props.stopLoading();
                }
            });

    }


    /**
     *
     */
    componentWillUnmount() {

        // if (this.intervalTimeout) {
        //     clearInterval(this.intervalTimeout);
        // }
    }

    /**
     *
     * @returns {*}
     */
    render() {

        if (this.state.jobs) {

            const filter = (job) => {

                let trigger = this.props.subSection;

                if (this.props.subSection === 'Scrapers')
                    trigger = 'Scraper';

                if (
                    job
                    && (
                        this.props.subSection === 'All'
                        || job.data.commandName.indexOf(trigger) > -1
                    )
                 ) {
                     return job;
                 } else {
                     return null;
                 }

            };

            const jobs = {
                active: this.state.jobs.active.filter(filter),
                queued: this.state.jobs.queued.filter(filter),
                failed: this.state.jobs.failed.filter(filter)
            };

            return (
                <div className="row">
                    <div className="col-4 d-flex flex-column">
                        <p className="alert alert-success" key="count">{"Active: " + jobs.active.length}</p>
                        <button onClick={()=>this.getJobs()} className="btn" >Refresh Jobs</button>
                        <div className="jobs-column">
                            {jobs.active.map((job, j) => {
                                return <Job key={j} job={job} />;
                            })}
                        </div>
                    </div>

                    <div className="col-4 d-flex flex-column">
                        <p className="alert alert-info" key="count">{"Queued: " + jobs.queued.length}</p>
                        <div className="jobs-column">
                            {jobs.queued.map((job, j) => {
                                return <Job key={j} job={job} />;
                            })}
                        </div>
                    </div>

                    <div className="col-4 d-flex flex-column">
                        <p className="alert alert-danger" key="count">{"Failed: " + jobs.failed.filter((job) => job.displayName.indexOf('App') === -1).length}</p>
                        <div className="jobs-column">
                            {jobs.failed.filter((job) => job.displayName.indexOf('App') === -1).map((job, j) => {
                                return (
                                    <Job
                                        key={j}
                                        job={JSON.parse(JSON.stringify(job))}
                                        API={this.props.API}
                                        getJobs={this.getJobs}
                                        startLoading={this.props.startLoading}
                                        stopLoading={this.props.stopLoading}
                                    />
                                );
                            })}
                        </div>
                    </div>

                </div>
            );
        }
        else {
            return (
                <div className="row">

                    <div className="col-4 d-flex flex-column">
                        <p className="alert alert-success" key="count">Active: Loading...</p>
                        <div className="jobs-column">
                            <Paper
                                classes={{
                                    root: "d-flex flex-column job"
                                }} >
                                <div className="d-flex justify-content-between">
                                    <small>Loading...</small>
                                </div>
                            </Paper>
                        </div>
                    </div>

                    <div className="col-4 d-flex flex-column">
                        <p className="alert alert-info" key="count">Queued: Loading...</p>
                        <div className="jobs-column">
                            <Paper
                                classes={{
                                    root: "d-flex flex-column job"
                                }} >
                                <div className="d-flex justify-content-between">
                                    <small>Loading...</small>
                                </div>
                            </Paper>
                        </div>
                    </div>

                    <div className="col-4 d-flex flex-column">
                        <p className="alert alert-danger" key="count">Failed: Loading...</p>
                        <div className="jobs-column">
                            <Paper
                                classes={{
                                    root: "d-flex flex-column job"
                                }} >
                                <div className="d-flex justify-content-between">
                                    <small>Loading...</small>
                                </div>
                            </Paper>
                        </div>
                    </div>

                </div>
            );
        }
    }
}

export default Jobs;