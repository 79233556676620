import React from 'react';
import update from 'immutability-helper';
import FormDialog from "../shared/FormDialog";
import ConfirmDelete from "../shared/ConfirmDelete";
import Select from 'react-select';
import SelectStyles from '../../utils/SelectStyles';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
    Fab,
    Icon
} from '@mui/material';

class InventoryFeeds extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {

        super(props);

        this.NotDynamicFeedType = [
            'MICROSOFT',
            'FACEBOOK',
            // 'LOCAL'
        ];

        this.dynamicSelectTypes = [
            {
                value: 'MICROSOFT',
                label: 'MICROSOFT'
            },
            {
                value: 'FACEBOOK',
                label: 'FACEBOOK'
            },
            {
                value: 'FBAUTO',
                label: 'FBAUTO'
            },
            {
                value: 'LOCAL',
                label: 'LOCAL'
            },
            {
                value: 'AUTOWEB',
                label: 'AUTOWEB'
            },
            {
                value: 'DYNAMIC',
                label: 'DYNAMIC'
            },
            {
                value: 'VLA',
                label: 'VLA'
            },
            {
                value: 'TTD',
                label: 'TTD'
            }
        ]

        this.sharedMethods = {
            handleInputChange: (...args) => {
                this.setState(this.props.handleInputChange(this.state, args));
            },
            confirmDelete: (...args) => {
                this.setState(this.props.confirmDelete(this.state, args));
            },
            handleClickOpen: (...args) => {
                this.setState(this.props.handleClickOpen(args));
            },
            handleRequestClose: (...args) => {
                this.setState(this.props.handleRequestClose(this.state, args));
            },
            validate: (...args) => {
                const results = this.props.validate(this.state, args);
                this.setState(results.state);
                return results.validated;
            },
            generateInputState: (...args) => {
                return this.props.generateInputState(args);
            },
            generateErrorState: (...args) => {
                return this.props.generateErrorState(args);
            }
        };

        this.state = {
            dynamicSelectType: [],
            inputs: this.sharedMethods.generateInputState(this.props.form),
            errors: this.sharedMethods.generateErrorState(this.props.form),
            clients: [],
            activations: [],
            originalActivations: [],
            open: false,
            edit: false,
            editId : null,
            deleteIds: null,
            confirmDeleteOpen: false
        };
    }

    setActivations(activations, type) {

        if(
            !(activations?.length)
        ) return;

        switch(
            type
        ) {
            // case 'Dynamic':
            //     return activations.filter(({tab})=>tab.includes('MICROSOFT'));
            default:
                return activations
        }

    }

    /**
     *
     * @param props
     */
    componentDidUpdate(props) {

        if (props.subSection !== this.props.subSection) {

            // console.log(
            //     this.props.subSection
            // );

            this.props.startLoading();

            this.props.API({
                service: 'management',
                route: this.props.inventoryType.toLowerCase()+'/index'
            })
                .then(data => {
                    if(data && this.state) {
                        this.props.stopLoading();
                        this.setState({
                            activations: this.setActivations(data, this.props.inventoryType),
                            originalActivations: data
                        }, () => {
                            this.doSelectDynamicSelect(
                                this.state.dynamicSelectType
                            );
                        });
                    }

                });
        }
    }


    /**
     *
     */
    componentDidMount() {

        this.props.startLoading();

        console.log('data!');

        Promise.all([
            this.props.API({
                service: 'management',
                route: this.props.inventoryType.toLowerCase()+'/index'
            }),
            this.props.API({
                service: 'management',
                route: 'client/scraper/index'
            })
        ]).then(data=>{

            this.props.stopLoading();
            console.log(data);

            if(
                !this.state
                || !data
            ) return;

            const [activations, clients] = data;
            console.log(activations);
            this.setState({
                activations: this.setActivations(activations, this.props.inventoryType) ?? [],
                originalActivations: activations,
                clients: clients ?? []
            }, () => {
                this.doSelectDynamicSelect(
                    this.state.dynamicSelectType
                );
            });


        });

    }


    /**
     *
     */
    submitActivation() {

        let inputs = this.state.inputs;
        let keys = [ 'client_id', 'enabled', 'scraper_id' ];

        for( let key of keys ) {
            if (
                inputs
                && inputs[key]
                && typeof inputs[key] === 'object'
                && 'value' in inputs[key]
            ) {
                inputs[key] = inputs[key].value;
            }
        }

        const send = () => {

            if (this.sharedMethods.validate(this.props.form)) {

                this.props.startLoading();
                const url = this.state.edit ? ''+this.props.inventoryType.toLowerCase()+'/update' : ''+this.props.inventoryType.toLowerCase()+'/store';

                let payLoad;
                if (this.state.edit) {
                    payLoad = {
                        inputs: this.state.inputs,
                        id: this.state.editId
                    };
                }
                else {
                    payLoad = this.state.inputs;
                }

                this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.props.form),
                    this.sharedMethods.generateErrorState(this.props.form)
                );

                this.props.API({
                    service: 'management',
                    route: url,
                    data: payLoad
                })
                    .then(data => {

                        this.props.stopLoading();
                        this.setState({
                            activations: this.setActivations(data, this.props.inventoryType),
                            originalActivations: data
                        }, () => {
                            this.doSelectDynamicSelect(
                                this.state.dynamicSelectType
                            );
                        })
                    });
            }

        };

        this.setState({
            inputs: inputs
        }, send );

    }

    /**
     *
     * @param id
     * @param index
     */
    deleteActivation(id, index) {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: this.props.inventoryType.toLowerCase()+'/destroy/'+id
        })
            .then( (d) => {

                this.props.API({
                    service: 'management',
                    route: this.props.inventoryType.toLowerCase()+'/index'
                })
                    .then(data => {
                        if(data && this.state) {
                            this.props.stopLoading();
                            this.setState({
                                activations: this.setActivations(data, this.props.inventoryType),
                                originalActivations: data
                            }, ()=> {

                                this.doSelectDynamicSelect(
                                    this.state.dynamicSelectType
                                );

                                this.setState(update(this.state, {
                                    confirmDeleteOpen: {$set: false }
                                }));

                            });
                        }

                    });
            });
    }


    /**
     *
     * @param activation
     * @param id
     */
    editActivation(activation, id) {

        this.sharedMethods.handleClickOpen(update(this.state, {
            inputs: {$set: activation},
            edit: {$set: true },
            editId: {$set: id }
        }));
    }

    doSelectDynamicSelect(e) {

        this.setState({
            dynamicSelectType: !e?.length
                ? null
                : e
        }, () => {

            let a = [];

            const m = e?.length
                ? e.map(a=>a.value)
                : null;

            if(
                !m
                || m.includes('ALL')
            ) {

                this.setState({
                    activations: this.state.originalActivations
                });

                return;

            } else {

                for(let value of m) {

                    switch(value) {
                        case 'MICROSOFT':
                        case 'FACEBOOK':
                        case 'LOCAL':
                        case 'FBAUTO':
                        case 'AUTOWEB':
                        case 'VLA':
                        case 'TTD':
                            a = [
                                ...a,
                                ...this.state.originalActivations.filter(
                                    ({tab})=>tab.includes(value)
                                )
                            ];
                            break;
                        case 'DYNAMIC':
                            a = [
                                ...a,
                                ...this.state.originalActivations.filter(
                                    ({tab}) => {
                                        const has = this.NotDynamicFeedType.filter( s => tab.includes(s) );
                                        return !has.length
                                    }
                                )
                            ];
                            break;
                    }

                }

                if(a)
                    this.setState({
                        activations: a
                    });

            }

        });

    }

    doSelect = () => <Select
        autoFocus
        tabIndex="1"
        styles={SelectStyles}
        key='search-type-select'
        isMulti={true}
        isSearchable={true}
        placeholder='Dynamic Feed Types to Show'
        value={this.state.dynamicSelectType}
        onChange={ (e) => { this.doSelectDynamicSelect(e) } }
        options={this.dynamicSelectTypes}
    />;

    showSelectType = () => {

        switch(
            this.props.inventoryType
        ) {
            case 'Dynamic':
                return this.doSelect();
            default:
                return null;
        }

    }

    /**
     *
     * @returns {*[]}
     */
    render() {

        return [
            <Fab
                size='small'
                key="add"
                onClick={() => this.sharedMethods.handleClickOpen(this.state)}
                classes={{
                    root: 'add-button'
                }} >

                <Icon>add</Icon>
            </Fab>,

            this.showSelectType(),

            <List key="list" id={this.props.inventoryType.toLowerCase()} classes={{
                root: 'active-list'
            }}>
                <ListItem id="first-row" classes={{
                    root: 'd-flex activation-row'
                }}>

                    <ListItemText
                        primary="Enabled"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                    <ListItemText
                        primary="Client"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                    <ListItemText
                        primary="Client Map Key"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                    <ListItemText
                        primary="Google Sheet Tab"
                        classes={{
                            root: 'list-item column-header'
                        }}
                    />

                </ListItem>


                {this.state.activations.map((activation, d) => {

                    if(!activation.scraper_model?.map_key) {
                        console.log(activation);
                    }

                    return [
                        <Divider key="divider" light/>,
                        <ListItem key={d} classes={{
                            root: 'd-flex activation-row'
                        }}>
                            <ListItemIcon
                                classes={{
                                    root: 'list-item enabled-button'
                                }}>

                                {!activation.enabled ? (
                                    <Icon>
                                        pause
                                    </Icon>
                                ) : (
                                    <Icon classes={{
                                        root: 'active-icon'
                                    }}>
                                        fiber_manual_record
                                    </Icon>
                                )}
                            </ListItemIcon>

                            <ListItemText
                                classes={{
                                    root: 'list-item'
                                }}
                                primary={activation.client.name}
                            />

                            <ListItemText
                                classes={{
                                    root: 'list-item'
                                }}
                                primary={activation.scraper_model.map_key}
                            />

                            <ListItemText
                                classes={{
                                    root: 'list-item'
                                }}
                                primary={activation.tab}
                            />

                            <Button
                                variant='contained'
                                onClick={() => this.editActivation(activation, activation.id)}
                                classes={{
                                    root: 'inventory-edit'
                                }} >

                                <Icon>mode_edit</Icon>
                                Edit
                            </Button>

                            <Button
                                variant='contained'
                                onClick={() => this.sharedMethods.confirmDelete(activation.id, d)}
                                classes={{
                                    root: 'inventory-delete'
                                }} >

                                <Icon>delete</Icon>
                                Delete
                            </Button>

                        </ListItem>
                    ];
                })}
            </List>,
            <FormDialog
                key="dialog"
                entityName={this.props.inventoryType + " Feed"}
                scrapers={true}
                open={this.state.open}
                edit={this.state.edit}
                errors={this.state.errors}
                inputs={this.state.inputs}
                clients={this.state.clients}
                form={this.props.form}
                submit={() => this.submitActivation()}
                handleInputChange={(e, key) => this.sharedMethods.handleInputChange(e, key, 'inputs')}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.props.form),
                    this.sharedMethods.generateErrorState(this.props.form)
                )}
            />,

            <ConfirmDelete
                key="delete"
                confirmDeleteOpen={this.state.confirmDeleteOpen}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.props.form),
                    this.sharedMethods.generateErrorState(this.props.form)
                )}
                deleteIds={this.state.deleteIds}
                delete={(id, index) => this.deleteActivation(id, index)}
            />

        ];
    }
}

export default InventoryFeeds;