import update from 'immutability-helper';

/**
 *
 * @param state
 * @param args
 * @returns {{}}
 */
export const handleInputChange = (state, args) => {

    // console.log(state.inputs,args);

    const event = args[0];
    const key = args[1];

    let subSection = null;
    if (typeof args[2] !== 'undefined') {
        subSection = args[2];
    }

    let adIndex = null;
    if (typeof args[3] !== 'undefined') {
        adIndex = args[3];
    }

    if (args[1] === 'seim_keyword_cpc' && String(args[0].target.value).indexOf('$') > -1) {
        return;
    }

    let value;

    if ( event ) {
        if ( 'target' in event ) {
            if( event.target.type === 'checkbox' ) {
                value = event.target.checked;
            } else {
                value = event.target.value;
            }
        }
        else {
            value = event;
        }
    }
    else {
        throw new Error('no Event!');
    }

    // PATCH TO SET STATE ON DYNAMIC DISPLAY / SEIM FEED DIALOG PROMPT,
    // SPECIFICALLY THE ISSUE WAS THAT SCRAPERS / SEIM FEEDS WOULD NOT BE SET IN STATE WHEN SET TO DEFAULT INDEX 0 in DialogPrompt
    if ('activations' in state && key === 'client_id') {
        const scrapers = state.clients.find(client => client.id === value.value ).scraper;
        state = update(state, { inputs: { scraper_id: {$set: scrapers[0].id }}} );
    }
    else if ('scripts' in state && key === 'client_id') {
        const feeds = state.clients.find(client => client.id === value.value ).seim;
        state = update(state, { inputs: { seim_feed_id: {$set: feeds[0].id }}} );
    }

    if (adIndex !== null) {

        if (key === 'geo_name') {
            return update(state, {[subSection]: {geos: {[adIndex]: {[key]: {$set: value }}}}});
        }
        else if ('ads' in state[subSection]) {
            let s = update(state, {[subSection]: {ads: {[adIndex]: {[key]: {$set: value }}}}});
            // console.log(s, subSection, adIndex, key);
            return s;
        }
        else if ('campaigns' in state[subSection] && state[subSection].campaigns.length > 0) {
            return update(state, {[subSection]: {campaigns: {[adIndex]: {[key]: {$set: value }}}}});
        }
        else if ('adsets' in state[subSection] && state[subSection].adsets.length > 0) {
            return update(state, {[subSection]: {adsets: {[adIndex]: {[key]: {$set: value }}}}});
        }
    }
    else if (subSection !== null) {
        return update(state, {[subSection]: {[key]: {$set: value }}});
    }
    else {
        return update(state, {[key]: {$set: value }});
    }
};


/**
 *
 * @param state
 * @param args
 * @returns {{deleteIds: {$set: {id: *, index: *}}, confirmDeleteOpen: {$set: boolean}}}
 */
export const confirmDelete = (state, args) => {
    const id = args[0];
    const index = args[1];

    return update(state, {
        deleteIds: {$set: {
            id: id,
            index: index
        }},
        confirmDeleteOpen: {$set: true }
    });
};


/**
 *
 * @param args
 * @returns {{open: {$set: boolean}}}
 */
export const handleClickOpen = (args) => {

    if ('showTags' in args[0]) {
        args[0] = update(args[0], {
            showTags: {$set: false}
        });
    }

    return update(args[0], {
        open: {$set: true}
    });
};


/**
 *
 * @param state
 * @param args
 * @returns {{edit: {$set: boolean}, editId: {$set: null}, open: {$set: boolean}, confirmDeleteOpen: {$set: boolean}, deleteIds: {$set: null}, errors: {$set: *}, inputs: {$set: *}}}
 */
export const handleRequestClose = (state, args) => {
    const inputState = args[0];
    const errorState = args[1];

    if ('showTags' in state) {
        state = update(state, {
            showTags: {$set: true}
        });
    }

    return update(state, {
        edit: {$set: false},
        editId: {$set: null},
        open: {$set: false},
        confirmDeleteOpen: {$set: false},
        deleteIds: {$set: null},
        errors: {$set: errorState},
        inputs: {$set: inputState}
    });
};


/**
 *
 * @param args
 * @returns {{}}
 */
export const generateInputState = (args) => {
    let state = {};
    args[0].rows.forEach((row, r) => {
        row.inputs.forEach((input, i) => {
            state[input.key] = input.default;
        });
    });

    if (typeof args[1] !== 'undefined') {
        state[args[1].type] = [];
    }

    if (typeof args[2] !== 'undefined') {
        state[args[2].type] = [];
    }

    return state;
};


/**
 *
 * @param args
 * @returns {{}}
 */
export const generateErrorState = (args) => {
    let state = {};
    args[0].rows.forEach((row, r) => {
        row.inputs.forEach((input, i) => {
            state[input.key] = false;
        });
    });
    return state;
};


/**
 *
 * @param state
 * @param args
 * @returns {{validated: boolean, state: *}}
 */
export const validate = (state, args) => {
    let validated = true;

    for (let row of args[0].rows) {
        for (let input of row.inputs) {

            // RESET ALL ERRORS TO FALSE
            state = update(state, {
                errors: {
                    [input.key]: {$set: false }
                }
            });

            let value = state.inputs[input.key];

            if (typeof state.inputs[input.key] === 'string') {
                value = value.trim();
            }

            // VALIDATE
            // if validated
            // & if input is not nullable
            // & if input is supposed to be validated,
            // & the value is equal to the default
            // & input.type is not 'number'

            let setError = false;

            if ( validated && !input.nullable && !input.noValidate ) {

                if( input.type === 'number' ) {

                    // these have min,max to compare, and can be default value!
                    if (
                        (
                            ( input.min || input.min === 0 )
                            && value < input.min
                        ) || (
                            ( input.max || input.max === 0 )
                            && value > input.max
                        )
                        || (!value || !value.toString().length) && input.nullable === false
                    ) {
                        // console.log('error!!!', value, value.toString().length, input.nullable);
                        setError = true;
                    }

                } else { // for any other type

                    value = typeof value === 'object' && 'value' in value
                        ? value.value
                        : value;

                    setError = value === input.default
                        || !String(value).length;

                }

            }

            if ( setError ) {

                state = update(state, {
                    errors: {
                        [input.key]: {$set: true }
                    }
                });
                validated = false;
                break;

            }

        }
        if (!validated) {
            break;
        }
    }

    return {
        validated: validated,
        state: state
    }
};
