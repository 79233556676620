import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loading from "./utils/Loading";
// import { GoogleLogin } from 'react-google-login';

class Login extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loginError: '',
            submitting: false
        };
    }


    /**
     *
     * @param e
     */
    handleInputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };


    /**
     *
     * @param e
     */
    handleFormSubmit = (e) => {

        e.preventDefault();

        this.setState({ submitting: true });

        this.props.API({
            service: 'auth',
            method: 'login',
            data: this.state
        })
            .then((data) => {

                if (data) {
                    this.props.setAuthorizedUser(data);
                }
                else {
                    this.setState({
                        loginError: true,
                        submitting: false
                    });
                }
            })
    };

    finishLogin(data) {

        this.setState({ submitting: true });

        this.props.API({
            service: 'auth',
            method: 'login',
            data: data
        })
            .then((data) => {

                if (data) {
                    this.props.setAuthorizedUser(data);
                }
                else {
                    this.setState({
                        loginError: true,
                        submitting: false
                    });
                }
            })
    }

    /**
     *
     * @returns {*}
     */
    render() {

        return (
            <div id="login" className={classNames('d-flex','align-items-center','justify-content-center')}>

                <form onSubmit={this.handleFormSubmit} className={classNames('d-flex','flex-column')}>
                    <div id="panel">

                        {/*LOGO*/}
                        <img id="login-logo" src={window.location.protocol+'//'+window.location.hostname+"/images/logo.png"} alt="The Fuel Gauge Logo"/>

                        {/*LOGIN ERROR*/}
                        {this.state.loginError ? (
                            <div onSubmit={this.handleFormSubmit} id="login-error">
                                Username or Password are either incorrect or don't match, please try again.
                            </div>
                        ) : null}

                        {/*USERNAME*/}
                        <div className={classNames("input-group","d-flex")}>
                            <div className={classNames("label","d-flex",'align-items-center')}>Username/ Email</div>
                            <input onChange={this.handleInputChange} type="text" id="username" value={this.state.username}/>
                        </div>

                        {/*PASSWORD*/}
                        <div className={classNames("input-group","d-flex")}>
                            <div className={classNames("label","d-flex",'align-items-center')}>Password</div>
                            <input onChange={this.handleInputChange} type="password" id="password" value={this.state.password} autoComplete='off' />
                        </div>
                    </div>
                    <div className='login-container'>
                        <input disabled={this.state.submiting} type="submit" id="submit" className="btn" value="Log In"/>

                        {/* <div>
                            <GoogleLogin
                                hostedDomain="themorangroup.net"
                                clientId="292429041381-8muiaem3tv2uk1dqolb0qnjdueu95pn8.apps.googleusercontent.com"
                                buttonText="Login With Google"
                                onSuccess={(d)=>this.finishLogin(d)}
                                onFailure={(d)=>this.finishLogin(d)}
                                cookiePolicy={'single_host_origin'}
                                isSignedIn={this.props.isLoggedIn}
                            />
                        </div> */}
                    </div>

                </form>


            </div>
        );
    }
}

Login.propTypes = {
    API: PropTypes.func.isRequired,
    setAuthorizedUser: PropTypes.func.isRequired
};

export default Login;
