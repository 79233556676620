import React from 'react';
import InventoryFeeds from './InventoryFeeds';
import Scrapers from '../inventory/Scrapers';
import Scripts from '../inventory/Scripts';
import Logs from '../inventory/Logs';

import Select from 'react-select';
import SelectStyles from '../../utils/SelectStyles';

import {
    Divider,
    Button
} from '@mui/material';


class Inventory extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.sharedMethods = {
            handleInputChange: (...args) => {
                this.setState(this.props.handleInputChange(this.state, args))
            }
        };

        this.feedForm = {
            rows: [
                {
                    inputs: [
                        {
                            name: 'Client',
                            key: 'client_id',
                            type: 'select',
                            nullable: false,
                            default: 'Select a client',
                            errorText: 'Client field cannot be empty.',
                            clients: true
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Client Map Key',
                            key: 'scraper_id',
                            type: 'select',
                            nullable: false,
                            default: 'Select a client map key',
                            errorText: 'Client map key field cannot be empty.',
                            scrapers: true
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Google Sheet Tab',
                            key: 'tab',
                            type: 'text',
                            nullable: false,
                            default: '',
                            errorText: 'Google sheet tab field cannot be empty.',
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Enabled',
                            key: 'enabled',
                            type: 'select',
                            noValidate: true,
                            default: 1,
                            nullable: true,
                            options: [
                                {
                                    name: 'Enabled',
                                    value: 1
                                },
                                {
                                    name: 'Paused',
                                    value: 0
                                }
                            ]
                        }
                    ]
                }
            ]
        };

        this.state = {
            scraper: [],
            script: [],
            dynamic: [],
            seim: [],
            scrapers: [],
            scripts: [],
            dynamicFeeds: [],
            seimFeeds: [],
        };
    }

    componentWillUnmount(){
        this.props.stopLoading();
    }

    /**
     *
     */
    componentDidMount() {

        this.props.startLoading();

        Promise.all(
            [
                this.props.API({
                    service: 'management',
                    route: 'scraper/index'
                }),
                this.props.API({
                    service: 'management',
                    route: 'script/index'
                }),
                this.props.API({
                    service: 'management',
                    route: 'dynamic/index'
                }),
                this.props.API({
                    service: 'management',
                    route: 'client/seim/index'
                })
            ]
        ).then( data => {

            // console.log(data);

            if(
                !this.state
                || !data
            )
                return;

            const [
                scrapers,
                scripts,
                dynamicFeeds,
                seimFeeds
             ] = data;

            // console.log(scrapers);

            for(
                let script of scripts
            ) {
                script.ads = script.ads.sort((a,b)=>a.id > b.id ? 1:-1);
            }

            this.setState({
                scrapers: scrapers ?? [],
                scripts: scripts ?? [],
                dynamicFeeds: dynamicFeeds ?? [],
                seimFeeds: seimFeeds
                    ? seimFeeds
                        .filter(g=>g.seim)
                        .map(f=>f.seim)
                        .flat()
                    : []
            }, () => {
                // console.log('here!', this.state);
                this.props.stopLoading();
            });

        });

        //scrapers,
        //scripts,
        //dynamicfeeds,
        //seimfeeds,

    //     this.props.API({
    //         service: 'management',
    //         route: 'client/seim/index'
    //     })
    //         .then(data => {

    //             this.setState({
    //                 seimFeeds: data
    //                     .filter(g=>g.seim)
    //                     .map(f=>f.seim)
    //                     .flat()
    //             }, () => {
    //                 this.props.stopLoading();
    //                 // console.log( this.state.seimFeeds[0])
    //             });
    //         });
    }


    /**
     *
     */
    deployScrapers() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'scraper/deploy/all'
        })
            .then(data => {
                this.props.stopLoading();
            });
    }


    /**
     *
     */
    deploySEIM() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'seim/deploy/all'
        })
            .then(data => {
                this.props.stopLoading();
            });
    }


    /**
     *
     */
    deployDynamic() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'dynamic/deploy/all'
        })
            .then(data => {
                this.props.stopLoading();
            });
    }


    /**
     *
     */
    deployScripts() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'script/deploy/all'
        })
            .then(data => {
                this.props.stopLoading();
            });
    }


    /**
     *
     */
    runUniqueScraper() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'scraper/deploy/some',
            data: {
                tabs: this.state.scraper.map(o=>o.value)
            }
        })
            .then(data => {
                this.setState({
                    scraper: []
                }, this.props.stopLoading )
            });
    }


    /**
     *
     */
    runUniqueScript() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'script/deploy/some',
            data: {
                ids: this.state.script.map(o=>o.value)
            }
        })
            .then(data => {
                this.setState({
                    script: []
                }, this.props.stopLoading )
            });
    }


    /**
     *
     */
    runUniqueDynamic() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'dynamic/deploy/some',
            data: {
                tabs: this.state.dynamic.map(o=>o.value)
            }
        })
            .then(data => {
                this.setState({
                    dynamic: []
                }, this.props.stopLoading )
            });
    }



    /**
     *
     */
    runUniqueSeimFeed() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'seim/deploy/some',
            data: {
                tabs: this.state.seim.map(o=>o.value)
            }
        })
            .then(data => {
                this.setState({
                    seim: []
                }, this.props.stopLoading )
            });
    }

    /**
     *
     * @returns {*}
     */
    render() {

        if (this.props.subSection === 'Scrapers') {
            return (
                <Scrapers {...this.props} />
            );
        }
        else if (this.props.subSection === 'SEIM Feeds') {
            return (
                <InventoryFeeds
                    inventoryType="SEIM"
                    form={this.feedForm}
                    {...this.props}
                />
            );
        }
        else if (this.props.subSection === 'Dynamic Feeds') {
            return (
                <InventoryFeeds
                    inventoryType="Dynamic"
                    form={this.feedForm}
                    {...this.props}
                />
            );
        }
        else if (this.props.subSection === 'SEIM Templates') {
            return (
                <Scripts {...this.props} />
            );
        }
        else if (this.props.subSection === 'SEIM Logs') {
            return (
                <Logs {...this.props} />
            );
        }
        else if (this.props.subSection === 'Run') {

            // somebody keeps running this, take it away
            // <Button
            //     classes={{
            //         root: 'submit'
            //     }}
            //     onClick={() => this.deployScripts()} variant='contained' >

            //     Populate SEIM campaigns
            // </Button>

            return [
                <div key="deploy" className="d-flex">
                    <Button
                        classes={{
                            root: 'submit'
                        }}
                        onClick={() => this.deployScrapers()} variant='contained' >

                        Deploy Scrapers
                    </Button>
                    <Button
                        classes={{
                            root: 'submit'
                        }}
                        onClick={() => this.deploySEIM()} variant='contained' >

                        Generate SEIM Feeds
                    </Button>
                    <Button
                        classes={{
                            root: 'submit'
                        }}
                        onClick={() => this.deployDynamic()} variant='contained' >

                        Generate Dynamic Feeds
                    </Button>

                </div>,

                <Divider key="divider4" light className="divider"/>,

                // DEPLOY SCRAPER

                <div className="d-flex" key="select4">
                    <div className='scraper-select'>
                        <Select
                            styles={SelectStyles}
                            key='scraper-select'
                            isMulti={true}
                            isSearchable={true}
                            placeholder='Select a scraper'
                            value={ this.state.scraper }
                            onChange={ (e) => this.sharedMethods.handleInputChange(e, 'scraper') }
                            noOptionsMessage={() => {
                                if ( this.state.scrapers.length ) {
                                    return 'No Scrapers!';
                                } else {
                                    return 'Loading Scrapers...';
                                }
                            }}
                            options={
                                this.state.scrapers.sort( (a, b) => {
                                    const textA = a.map_key.toUpperCase();
                                    const textB = b.map_key.toUpperCase();
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                }).map( ( scraper, i ) => {
                                    return { value: scraper.map_key, label: scraper.map_key }
                                })
                            }
                        />
                    </div>
                    <Button
                        classes={{
                            root: 'submit submit-unique'
                        }}
                        onClick={() => this.runUniqueScraper()} variant='contained' >

                        Run unique scraper
                    </Button>

                </div>,

                <Divider key="divider3" light className="divider"/>,

                // DEPLOY DYNAMIC FEED

                <div className="d-flex" key="select1">
                    <div className='scraper-select'>
                        <Select
                            styles={SelectStyles}
                            key='seim-select'
                            isMulti={true}
                            isSearchable={true}
                            placeholder='Select a SEIM Feed'
                            value={ this.state.seim }
                            onChange={ (e) => this.sharedMethods.handleInputChange(e, 'seim') }
                            noOptionsMessage={() => {
                                if ( this.state.dynamicFeeds.length ) {
                                    return 'No SEIM Feeds!';
                                } else {
                                    return 'Loading SEIM Feeds...';
                                }
                            }}
                            options={
                                this.state.seimFeeds.sort((a, b) => {
                                    const textA = a.tab.toUpperCase();
                                    const textB = b.tab.toUpperCase();
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                }).filter(feed => feed.enabled).map((seim, i) => {
                                    return { value: seim.tab, label: seim.tab }
                                })
                            }
                        />
                    </div>
                    <Button
                        classes={{
                            root: 'submit submit-unique'
                        }}
                        onClick={() => this.runUniqueSeimFeed()} variant='contained' >

                        Run unique SEIM feed
                    </Button>
                </div>,

                <Divider key="divider1" light className="divider"/>,

                // DEPLOY DYNAMIC FEED

                <div className="d-flex" key="select11">
                    <div className='scraper-select'>
                        <Select
                            key='scraper-select'
                            styles={SelectStyles}
                            isMulti={true}
                            isSearchable={true}
                            placeholder='Select a Dynamic Feed'
                            value={ this.state.dynamic }
                            onChange={ (e) => this.sharedMethods.handleInputChange(e, 'dynamic') }
                            noOptionsMessage={() => {
                                if ( this.state.dynamicFeeds.length ) {
                                    return 'No Dynamic Feeds!';
                                } else {
                                    return 'Loading Dynamic Feeds...';
                                }
                            }}
                            options={
                                this.state.dynamicFeeds.sort((a, b) => {
                                    const textA = a.tab.toUpperCase();
                                    const textB = b.tab.toUpperCase();
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                }).filter(feed => feed.enabled).map((dynamic, i) => {
                                    return { value: dynamic.tab, label: dynamic.tab }
                                })
                            }
                        />
                    </div>
                    <Button
                        classes={{
                            root: 'submit submit-unique'
                        }}
                        onClick={() => this.runUniqueDynamic()} variant='contained' >

                        Run unique dynamic feed
                    </Button>
                </div>,
                <Divider key="divider2" light className="divider"/>,

                // DEPLOY SCRIPT

                <div className="d-flex" key="select2">
                    <div className='scraper-select'>
                        <Select
                            styles={SelectStyles}
                            key='scraper-select'
                            isMulti={true}
                            isSearchable={true}
                            placeholder='Select a Script'
                            value={ this.state.script }
                            onChange={ (e) => this.sharedMethods.handleInputChange(e, 'script') }
                            noOptionsMessage={() => {
                                if ( this.state.scripts.length ) {
                                    return 'No Scripts!';
                                } else {
                                    return 'Loading Scripts...';
                                }
                            }}
                            options={
                                this.state.scripts.map( ( script, i ) => {
                                    if (script.enabled) {
                                        return {
                                            value: script.id,
                                            label: `${script.client.name} | ${script.campaign_name}`
                                        }
                                    }
                                    return null;
                                }).filter( i => {
                                    if ( i && 'value' in i ) {
                                        return i;
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        />
                    </div>
                    <Button
                        classes={{
                            root: 'submit submit-unique'
                        }}
                        onClick={() => this.runUniqueScript()} variant='contained' >

                        Populate inventory
                    </Button>
                </div>
            ];
        }
        else {
            return null;
        }
    }
}

export default Inventory;