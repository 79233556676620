import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';

// brand icons
import {
    faFacebookF,
    faFacebookSquare
} from '@fortawesome/free-brands-svg-icons';

// solid icons
import {
    faPlus,
    faSignOutAlt,
    faBars,
    faCogs,
    faChevronRight,
    faChevronLeft,
    faSearchLocation,
    faMinusSquare,
    faPlusSquare,
    faLightbulb,
    faToggleOn,
    faToggleOff,
    faCaretUp,
    faCaretDown,
    faChevronCircleRight,
    faChevronCircleLeft,
    faFemale,
    faMale,
    faQuestion,
    faQuestionCircle,
    faMobileAlt,
    faTabletAlt,
    faTv,
    faDesktop,
    faChessQueen,
    faTrashAlt,
    faTimes,
    faThumbtack,
    faCheckCircle,
    faEye,
    faEyeSlash,
    faRedo,
    faMapMarkedAlt,
    faRetweet,
    faInfoCircle,
    faKeyboard,
    faUsers,
    faPercent,
    faStreetView
} from '@fortawesome/free-solid-svg-icons';

// regular icons
import {
    faLightbulb as farLightbulb
} from '@fortawesome/free-regular-svg-icons';

// add font awesome plus icon to library
library.add([
    faPlus,
    faCaretDown,
    faCaretUp,
    faSignOutAlt,
    faBars,
    faCogs,
    faChevronRight,
    faChevronLeft,
    faFacebookF,
    faTv,
    faFacebookSquare,
    faSearchLocation,
    faMinusSquare,
    faPlusSquare,
    faLightbulb,
    farLightbulb,
    faToggleOn,
    faToggleOff,
    faChevronCircleRight,
    faChevronCircleLeft,
    faFemale,
    faMale,
    faQuestion,
    faQuestionCircle,
    faMobileAlt,
    faTabletAlt,
    faDesktop,
    faChessQueen,
    faTrashAlt,
    faTimes,
    faThumbtack,
    faCheckCircle,
    faEye,
    faEyeSlash,
    faRedo,
    faMapMarkedAlt,
    faRetweet,
    faInfoCircle,
    faKeyboard,
    faUsers,
    faPercent,
    faStreetView
]);

const render = Component => {
    ReactDOM.render((
        // React-router
        <Router>
            <Component />
        </Router>
    ), document.getElementById('root'));
};

// service worker was causing issues on HTTPS at first,
// so manually unregistering if any were found for the domain
if ('serviceWorker' in navigator) {

    navigator.serviceWorker.getRegistrations()
        .then((registrations) => {

            for (let registration of registrations) {

                console.log(registration);

                registration.unregister().then(() => {
                    window.location.reload();
                });

            }

        })
        .catch(function (err) {

            console.log('Service Worker registration failed: ', err);

        });
}

render(App);

if (module.hot) {
    // console.log('hot reload!');
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}