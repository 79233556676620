import React from 'react';
import classNames from 'classnames';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardActions,
    CardContent,
    Typography,
    TextField,
    Divider,
    Button,
    Fab,
    InputAdornment,
    Icon
} from '@mui/material';

import update from 'immutability-helper';
import FormDialog from "../shared/FormDialog";
import ConfirmDelete from "../shared/ConfirmDelete";

class Scripts extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.sharedMethods = {
            handleInputChange: (...args) => {
                this.setState(this.props.handleInputChange(this.state, args));
            },
            confirmDelete: (...args) => {
                this.setState(this.props.confirmDelete(this.state, args));
            },
            handleClickOpen: (...args) => {
                this.setState(this.props.handleClickOpen(args));
            },
            handleRequestClose: (...args) => {
                this.setState(this.props.handleRequestClose(this.state, args));
            },
            validate: (...args) => {
                const results = this.props.validate(this.state, args);
                this.setState(results.state);
                return results.validated;
            },
            generateInputState: (...args) => {
                return this.props.generateInputState(args);
            },
            generateErrorState: (...args) => {
                return this.props.generateErrorState(args);
            }
        };

        this.form = {
            rows: [
                {
                    inputs: [
                        {
                            name: 'Client',
                            key: 'client_id',
                            type: 'select',
                            nullable: false,
                            default: 'Select a client',
                            errorText: 'Client field cannot be empty.',
                            clients: true
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'SEIM Feed',
                            key: 'seim_feed_id',
                            type: 'select',
                            nullable: false,
                            default: 'Select an SEIM feed',
                            errorText: 'SEIM feed field cannot be empty.',
                            feeds: true
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Enabled',
                            key: 'enabled',
                            type: 'select',
                            errorText: 'Enabled field cannot be empty.',
                            noValidate: true,
                            default: 1,
                            nullable: true,
                            options: [
                                {
                                    name: 'Enabled',
                                    value: 1
                                },
                                {
                                    name: 'Paused',
                                    value: 0
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Platform',
                    inputs: [
                        {
                            name: 'Google',
                            key: 'google',
                            type: 'checkbox',
                            noValidate: true,
                            default: true
                        },
                        {
                            name: 'Bing',
                            key: 'bing',
                            type: 'checkbox',
                            noValidate: true,
                            default: true
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Spanish',
                            key: 'spanish',
                            type: 'checkbox',
                            noValidate: true,
                            default: false
                        },
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Mileage Threshold',
                            key: 'mileage_threshold',
                            type: 'number',
                            nullable: false,
                            default: '',
                            errorText: 'Mileage threshold field cannot be empty.'
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Campaign Name',
                            key: 'campaign_name',
                            type: 'text',
                            nullable: false,
                            default: '',
                            errorText: 'Campaign name field cannot be empty.'
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Keyword URL',
                            key: 'keyword_url',
                            type: 'text',
                            nullable: false,
                            default: '',
                            fullWidth: true,
                            errorText: 'Keyword URL field cannot be empty.'
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Final Ad URL',
                            key: 'final_url',
                            type: 'text',
                            nullable: false,
                            default: '',
                            fullWidth: true,
                            errorText: 'Final URL field cannot be empty.'
                        }
                    ]
                }
            ]
        };

        this.adForm = {
            rows: [
                {
                    inputs: [
                        {

                            name: 'Ad Type',
                            key: 'ad_type',
                            type: 'select',
                            errorText: 'Must be filled',
                            noValidate: true,
                            default: "ETA",
                            nullable: false,
                            options: [
                                {
                                    name: 'ETA',
                                    value: 'ETA'
                                },
                                {
                                    name: 'RSA',
                                    value: 'RSA',
                                }
                            ]
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Over {threshold} Miles',
                            key: 'over_mileage',
                            type: 'checkbox',
                            default: false,
                            noValidate: true
                        }
                    ]
                },
                {
                    inputs: [
                        {
                            name: 'Certified Pre-Owned',
                            key: 'cpo',
                            type: 'checkbox',
                            default: false,
                            noValidate: true
                        }
                    ]
                }
            ]
        };

        for(let i = 1; i < 16; i++) {
            this.adForm.rows.push( { inputs: [ {
                name: `Headline Part ${i}`,
                key: `headline_part${i}`,
                type: 'text',
                nullable: false,
                default: '',
                fullWidth: true,
                errorText: `Headline Part ${i} field cannot be empty.`,
            } ] } );
        }

        for(let i = 1; i < 5; i++) {
            this.adForm.rows.push({inputs:[{
                name: `Description Part ${i}`,
                key: `description_part${i}`,
                type: 'text',
                nullable: false,
                default: '',
                fullWidth: true,
                errorText: `Description Part ${i} field cannot be empty.`,
            }]});
        }

        this.geoForm = {
            rows: [
                {
                    inputs: [
                        {
                            name: 'Geo name',
                            key: 'geo_name',
                            type: 'text',
                            nullable: false,
                            default: '',
                            fullWidth: true,
                            errorText: 'Geo name can\'t be empty'
                        }
                    ]
                }
            ]
        };

        this.state = {
            inputs: this.sharedMethods.generateInputState(this.form, {
                type: 'ads',
                map: this.adForm
            }, {
                type: 'geos',
                map: this.geoForm
            }),
            errors: this.sharedMethods.generateErrorState(this.form),
            showTags: true,
            scripts: [],
            clients: [],
            filtered: null,
            search: '',
            open: false,
            edit: false,
            editId : null,
            deleteIds: null,
            confirmDeleteOpen: false
        };
    }


    /**
     *
     */
    componentDidMount() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'script/index'
        })
            .then(data => {
                this.setState({
                    scripts: data
                });
            });

        this.props.API({
            service: 'management',
            route: 'client/seim/index'
        })
            .then(data => {

                this.props.stopLoading();
                this.setState({
                    clients: data
                });
            });
    }


    /**
     *
     */
    submitScript() {

        let inputs = this.state.inputs;
        let keys = ['client_id','enabled','seim_feed_id'];

        for( let key of keys ) {
            if (
                inputs
                && inputs[key]
                && typeof inputs[key] === 'object'
                && 'value' in inputs[key]
            ) {
                inputs[key] = inputs[key].value;
            }
        }

        if (
            inputs?.ads
        ) {

            for (
                let ad of inputs.ads ?? []
            ) {
                ad.ad_type =
                    typeof ad.ad_type === 'object'
                    ? ad.ad_type.value
                    : ad.ad_type;
            }

        }

        const send = () => {

            if (this.sharedMethods.validate(this.form)) {

                this.props.startLoading();
                const url = this.state.edit ? 'script/update' : 'script/store';

                let payLoad;
                if (this.state.edit) {
                    payLoad = {
                        inputs: this.state.inputs,
                        id: this.state.editId
                    };
                }
                else {
                    payLoad = this.state.inputs;
                }

                this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form, {
                        type: 'ads',
                        map: this.adForm
                    }, {
                        type: 'geos',
                        map: this.geoForm
                    }),
                    this.sharedMethods.generateErrorState(this.form)
                );

                this.props.API({
                    service: 'management',
                    route: url,
                    data: payLoad
                })
                    .then(data => {

                        this.props.stopLoading();
                        if (this.state.filtered) {
                            this.setState({
                                scripts: data,
                                confirmDeleteOpen: false,
                                filtered: null,
                                search: ''
                            });
                        }
                        else {
                            this.setState({
                                scripts: data
                            })
                        }
                    });
            }
        };

        this.setState({
            inputs: inputs
        }, send );

    }


    /**
     *
     * @param id
     * @param index
     */
    deleteScript(id, index) {

        this.props.API({
            service: 'management',
            route: 'script/destroy/'+id
        })
            .then(data => {

                this.props.stopLoading();
                if (this.state.filtered) {
                    this.setState(update(this.state, {
                        confirmDeleteOpen: {$set: false },
                        filtered: {$set: null },
                        search: {$set: '' },
                        scripts: {$set: data}
                    }));
                }
                else {
                    this.setState(update(this.state, {
                        scripts: {$splice: [[index, 1]] },
                        confirmDeleteOpen: {$set: false }
                    }));
                }
            });
    }


    /**
     *
     * @param script
     */
    editScript(script) {

        let scriptState = {
            client_id: script.client_id,
            seim_feed_id: script.seim_feed_id,
            enabled: script.enabled,
            keyword_url: script.keyword_url,
            final_url: script.final_url,
            spanish: script.spanish,
            google: script.google,
            bing: script.bing,
            campaign_name: script.campaign_name,
            mileage_threshold: script.mileage_threshold
        };

        scriptState.ads = [];
        script.ads.forEach((ad, a) => {
            let adState = {};
            this.adForm.rows.forEach((row, r) => {
                row.inputs.forEach((input, i) => {
                    adState[input.key] = ad[input.key];
                });
                scriptState.ads[a] = adState;
            });
        });

        scriptState.geos = [];
        script.geos.forEach((geo, a) => {
            let geoState = {};
            this.geoForm.rows.forEach((row, r) => {
                row.inputs.forEach((input, i) => {
                    geoState[input.key] = geo[input.key];
                });
                scriptState.geos[a] = geoState;
            });
        });

        this.sharedMethods.handleClickOpen(update(this.state, {
            inputs: {$set: scriptState },
            edit: {$set: true },
            editId: {$set: script.id }
        }));
    }


    /**
     *
     */
    newAd() {

        let newAd= {};
        this.adForm.rows.forEach((row, r) => {
            row.inputs.forEach((input, i) => {
                newAd[input.key] = input.default;
            });
        });

        let ads = this.state.inputs.ads;
        ads.push(newAd);

        this.setState(update(this.state, {inputs: {ads: {$set: ads }}}));
    }


    /**
     *
     */
    newGeo() {

        let newGeo = {};
        this.geoForm.rows.forEach((row, r) => {
            row.inputs.forEach((input, i) => {
                newGeo[input.key] = input.default;
            });
        });

        let geos = this.state.inputs.geos;
        geos.push(newGeo);

        this.setState(update(this.state, {inputs: {geos: {$set: geos }}}));
    }


    /**
     * @param a
     */
    deleteAd(a) {
        this.setState(update(this.state, {
            inputs: {ads: {$splice: [[a, 1]] }},
            confirmDeleteOpen: {$set: false }
        }));
    }


    /**
     * @param a
     */
    deleteGeo(a) {
        this.setState(update(this.state, {
            inputs: {geos: {$splice: [[a, 1]] }},
            confirmDeleteOpen: {$set: false }
        }));
    }


    /**
     * @param a
     */
    searchScripts(e) {
        if (e.target.value === '') {
            this.setState({
                search: e.target.value,
                filtered: null
            });
        }
        else {
            this.setState({
                search: e.target.value,
                filtered: this.state.scripts.filter(script => {
                    if (script.client.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
            });
        }
    }


    /**
     *
     * @returns {*[]}
     */
    render() {

        let scripts;
        if (this.state.filtered) {
            scripts = this.state.filtered;
        }
        else {
            scripts = this.state.scripts;
        }

        return [
            <div className="d-flex" key="header">
                <Fab
                    size='small'
                    onClick={() => this.sharedMethods.handleClickOpen(this.state)}
                    classes={{
                        root: 'add-button'
                    }} >

                    <Icon>add</Icon>
                </Fab>
                <TextField
                    classes={{
                        root: "search-box"
                    }}
                    label="Search Templates"
                    type="text"
                    value={this.state.search ? this.state.search : ''}
                    onChange={(e) => this.searchScripts(e)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment classes={{
                                root: 'search-icon'
                            }}>
                                <Icon>search</Icon>
                            </InputAdornment>
                        )
                    }}
                />
            </div>,
            <div key="scripts" id="scripts">
                { this.state.showTags && scripts ? scripts.map((script, i) => {

                    if(
                        !script.feed?.tab
                    ) {
                        // console.log(script);
                    }

                    return (
                        <div key={i} className={classNames('d-flex', 'script-row', 'justify-content-start')}>
                            <Card raised classes={{
                                root: 'card'
                            }}>
                                <CardContent classes={{
                                    root: 'card-content'
                                }}>

                                    <div className={classNames('d-flex','justify-content-start','align-items-center', 'header')}>

                                        <Typography variant="h5">{script.client.name}</Typography>
                                        {!script.enabled ? (
                                            <Icon classes={{
                                                root: 'card-attr'
                                            }}>
                                                pause
                                            </Icon>
                                        ) : (
                                            <Icon classes={{
                                                root: 'active-icon card-attr'
                                            }}>
                                                fiber_manual_record
                                            </Icon>
                                        )}

                                        {script.spanish ? (
                                            <h6 className="spanish">
                                                SPANISH
                                            </h6>
                                        ) : (
                                            null
                                        )}

                                        {script.google ? (
                                            <h6 className="spanish">
                                                GOOGLE
                                            </h6>
                                        ) : (
                                            null
                                        )}

                                        {script.bing ? (
                                            <h6 className="spanish">
                                                BING
                                            </h6>
                                        ) : (
                                            null
                                        )}
                                    </div>

                                    <div className={classNames('d-flex','justify-content-start','align-items-center', 'header')}>
                                        <Typography variant="subtitle1"><small>Client ID:&nbsp;</small>{script.client.id}</Typography>
                                        &nbsp;<Typography variant="subtitle1"><small>Script ID:&nbsp;</small>{script.id}</Typography>
                                        &nbsp;{
                                            'acronym' in script.client && script.client.acronym
                                                ? <Typography variant="subtitle1"><small>Client Acronym:&nbsp;</small>{script.client.acronym}</Typography>
                                                : null
                                        }
                                    </div>

                                    <div className={classNames('d-flex', 'script-card-row')}>

                                        {script.spanish && script.client.adwords_spanish_seim_account_id ? (
                                            <div className="d-flex card-attr">
                                                <h6>SEIM Spanish AdWords CID:</h6>
                                                <span>{script.client.adwords_spanish_seim_account_id}</span>
                                            </div>
                                        ) : (
                                            <div className="d-flex card-attr">
                                                <h6>SEIM English AdWords CID:</h6>
                                                <span>{script.client.adwords_seim_account_id}</span>
                                            </div>
                                        )}

                                        {script.client.bing_seim_account_id ? (
                                            <div className="d-flex card-attr">
                                                <h6>Bing Ads CID:</h6>
                                                <span>{script.client.bing_seim_account_id}</span>
                                            </div>
                                        ) : (
                                            null
                                        )}

                                        <div className="d-flex card-attr">
                                            <h6>SEIM Feed Tab Name:</h6>
                                            <span>{script.feed.tab}</span>
                                        </div>

                                        <div className="d-flex card-attr">
                                            <h6>Campaign Name:</h6>
                                            <span>{script.campaign_name}</span>
                                        </div>

                                        <div className="d-flex card-attr">
                                            <h6>Mileage threshold:</h6>
                                            <span>{script.mileage_threshold}</span>
                                        </div>
                                    </div>

                                    <div className={classNames('d-flex', 'script-card-row')}>
                                        <div className="d-flex card-attr">
                                            <h6>Keyword URL:</h6>
                                            <span>{script.keyword_url}</span>
                                        </div>
                                    </div>

                                    <div className={classNames('d-flex', 'script-card-row')}>
                                        <div className="d-flex card-attr">
                                            <h6>Final URL:</h6>
                                            <span>{script.final_url}</span>
                                        </div>
                                    </div>

                                    <div className={classNames('d-flex', 'script-card-row')}>
                                        <div className="d-flex card-attr">
                                            <h6>Geos:</h6>
                                            {script.geos.map((geo, a) => {
                                                return (
                                                    <span key={a} className='px-2'>{geo.geo_name+(script.geos.length > 1 && a !== script.geos.length - 1 ? ', ' : '')}</span>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    {/*ADS*/}

                                    <Accordion classes={{
                                        root: 'ads-panel'
                                    }}>
                                        <AccordionSummary
                                            classes={{
                                                root: 'summary',
                                                expanded: 'panel-is-expanded',
                                                expandIcon: 'icon',
                                                content: 'panel-content'
                                            }}
                                            expandIcon={<Icon>expand_more</Icon>}>

                                            <h6>ADS</h6>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{
                                            root: 'panel-details'
                                        }}>
                                            <div className={classNames('d-flex flex-column', 'script-card-row', 'ads')}>
                                                {script.ads.map((ad, a) => {
                                                    return (
                                                        <div key={a} className="d-flex flex-column ad">
                                                            <div className="d-flex">
                                                                <h5>Ad {a+1}</h5>
                                                                <h6 className={ad.over_mileage ? "over" : "under"}>{ad.over_mileage ? 'Over '+script.mileage_threshold.toLocaleString()+' Miles' : 'Under '+script.mileage_threshold.toLocaleString()+' Miles'}</h6>
                                                                {ad.cpo ? (
                                                                    <h6 className="cpo">CPO</h6>
                                                                ) : (
                                                                    null
                                                                )}
                                                            </div>
                                                            <div className="d-flex card-attr">
                                                                <h6>Headline Part 1:</h6>
                                                                <span>{ad.headline_part1}</span>
                                                            </div>
                                                            <div className="d-flex card-attr">
                                                                <h6>Headline Part 2:</h6>
                                                                <span>{ad.headline_part2}</span>
                                                            </div>
                                                            <div className="d-flex card-attr">
                                                                <h6>Description:</h6>
                                                                <span>{ad.description}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                </CardContent>
                                <Divider light/>

                                <CardActions classes={{
                                    root: 'card-action'
                                }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => this.editScript(script)}
                                        classes={{
                                            root: 'inventory-edit'
                                        }} >

                                        <Icon>mode_edit</Icon>
                                        Edit
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={() => this.sharedMethods.confirmDelete(script.id, i)}
                                        classes={{
                                            root: 'inventory-delete'
                                        }} >

                                        <Icon>delete</Icon>
                                        Delete
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                    );
                }) : null}
            </div>,
            <FormDialog
                id="scripts-dialog"
                entityName="Template"
                ads={true}
                geos={true}
                adForm={this.adForm}
                geoForm={this.geoForm}
                deleteGeo={(a) => this.deleteGeo(a)}
                deleteAd={(a) => this.deleteAd(a)}
                open={this.state.open}
                edit={this.state.edit}
                errors={this.state.errors}
                inputs={this.state.inputs}
                clients={this.state.clients}
                newAd={() => this.newAd()}
                newGeo={() => this.newGeo()}
                form={this.form}
                submit={() => this.submitScript()}
                handleInputChange={(e, key, adIndex) => this.sharedMethods.handleInputChange(e, key, 'inputs', adIndex)}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form, {
                        type: 'ads',
                        map: this.adForm
                    }, {
                        type: 'geos',
                        map: this.geoForm
                    }),
                    this.sharedMethods.generateErrorState(this.form)
                )}
                key="dialog"
            />,
            <ConfirmDelete
                key="delete"
                confirmDeleteOpen={this.state.confirmDeleteOpen}
                handleRequestClose={() => this.sharedMethods.handleRequestClose(
                    this.sharedMethods.generateInputState(this.form, {
                        type: 'ads',
                        map: this.adForm
                    }, {
                        type: 'geos',
                        map: this.geoForm
                    }),
                    this.sharedMethods.generateErrorState(this.form)
                )}
                deleteIds={this.state.deleteIds}
                delete={(id, index) => this.deleteScript(id, index)}
            />
        ];
    }
}

export default Scripts;