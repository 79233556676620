import React from 'react';
import classNames from 'classnames';
import {
    Button,
    Fab,
    Icon,
    IconButton,
    InputLabel,
    TextField,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';

import Select from 'react-select';
import SelectStyles from '../../utils/SelectStyles';

class FormDialog extends React.PureComponent {

    /**
     *
     * @param input
     * @param i
     * @param adIndex
     * @param threshold
     * @returns {*}
     */
    renderInput(input, i, adIndex, threshold) {

        // DYNAMICALLY INSERT MILEAGE THRESHOLD VALUE
        if (threshold && input.name.indexOf('{threshold}') > -1) {
            input.name.replace('{threshold}', this.props.inputs.mileage_threshold.toLocaleString());
        }

        // CLIENTS DROPDOWN
        if (input.clients) {

            if(!this.props.clients) return;

            let client = this.props.clients.find( client => Number(client.id) === Number(this.props.inputs[input.key]) );
            let value;

            if (client && 'name' in client) {
                value = [ { value: this.props.inputs[input.key], label: `${client.name} ${(this.props.entityName.includes('Exemption') ? '- ' + client.domain : '' )} `  } ];
            }

            return (
                <FormControl
                    key="clients"
                    error={this.props.errors[input.key]}
                    fullWidth={true}
                    classes={{
                        root: 'clients'
                    }} >

                    <Select
                        styles={SelectStyles}
                        key='clients-select'
                        name="clients"
                        isMulti={false}
                        isSearchable={true}
                        placeholder='Select A Client'
                        value={ value }
                        onChange={(e) => this.props.handleInputChange(e, input.key)}
                        noOptionsMessage={() => {
                            if( this.props.clients.length ) {
                                return 'no matches!';
                            } else {
                                return 'Loading Client List...';
                            }
                        } }
                        options={
                            this.props.clients.map((client, i) => {
                                return {
                                    value: client.id,
                                    label: `${client.name} ${(this.props.entityName.includes('Exemption') ? '- ' + client.domain : '' )}`
                                }
                            })
                        }
                    />
                    <React.Fragment>{
                        this.props.errors[input.key] ?
                            <FormHelperText>{input.errorText}</FormHelperText>
                            : null
                    }</React.Fragment>
                </FormControl>
            );
        }

        // SCRAPERS DROPDOWN

        else if (input.scrapers) {

            let scrapers;
            if (this.props.inputs.client_id !== 'Select a client') {

                let client = this.props.clients.find( client => {
                    return client.id === (
                        typeof this.props.inputs.client_id === 'object'
                        && 'value' in this.props.inputs.client_id
                            ? this.props.inputs.client_id.value
                            : this.props.inputs.client_id
                    );
                });

                if (client && !('scraper' in client)) {
                    scrapers = [];
                }
                else {
                    scrapers = client.scraper;
                }

            }
            else {
                scrapers = [];
            }

            let value;
            if ( scrapers.length ) {
                value = [ { value: scrapers[0].id, label: scrapers[0].map_key } ];
            }

            return (
                <FormControl
                    key="scrapers"
                    error={this.props.errors[input.key]}
                    classes={{
                        root: 'scrapers'
                    }}
                    fullWidth={true}
                >
                    <Select
                        styles={SelectStyles}
                        key='scrapers-select'
                        name="scraper"
                        isMulti={false}
                        isSearchable={true}
                        placeholder='Select a scraper'
                        value={ value }
                        onChange={(e) => this.props.handleInputChange(e, input.key)}
                        noOptionsMessage={() => {
                            if( scrapers.length ) {
                                return 'no matches!';
                            } else {
                                return 'Loading Client List...';
                            }
                        } }
                        options={
                            scrapers.map((scraper, s) => {
                                return {
                                    value: scraper.id,
                                    label: scraper.map_key
                                }
                            })
                        }
                    />
                    <React.Fragment>{
                        this.props.errors[input.key] ?
                            <FormHelperText>You must select a scraper</FormHelperText>
                            : null
                    }</React.Fragment>

                </FormControl>
            );
        }

        // FEEDS DROPDOWN

        else if (input.feeds) {

            let seimFeeds;

            if (this.props.inputs.client_id !== 'Select a client') {

                let client = this.props.clients.find(client => {
                    return client.id === (
                        typeof this.props.inputs.client_id === 'object'
                        && 'value' in this.props.inputs.client_id
                            ? this.props.inputs.client_id.value
                            : this.props.inputs.client_id
                    );
                });

                if (client && !('seim' in client)) {
                    seimFeeds = [];
                }
                else {
                    seimFeeds = client.seim;
                }
            }
            else {
                seimFeeds = [];
            }

            let value;

            if ( seimFeeds.length ) {
                value = [ { value: seimFeeds[0].id, label: seimFeeds[0].tab } ];
            }

            return (
                <FormControl
                    key="scripts"
                    error={this.props.errors[input.key]}
                    classes={{
                        root: 'feeds'
                    }}
                    fullWidth={true}
                >

                    <Select
                        styles={SelectStyles}
                        key='feed-select'
                        name="feed"
                        isMulti={false}
                        isSearchable={true}
                        placeholder='Select an SEIM feed'
                        value={ value }
                        onChange={(e) => this.props.handleInputChange(e, input.key)}
                        noOptionsMessage={() => {
                            if( seimFeeds.length ) {
                                return 'no matches!';
                            } else {
                                return 'Loading Client List...';
                            }
                        } }
                        options={
                            seimFeeds.map((feed, s) => {
                                return {
                                    value: feed.id,
                                    label: feed.tab
                                }
                            })
                        }
                    />
                    <React.Fragment>{
                        this.props.errors[input.key] ?
                            <FormHelperText>You must select an SEIM feed</FormHelperText>
                            : null
                    }</React.Fragment>

                </FormControl>
            );
        }

        // SELECT INPUT OPTION DROPDOWN

        else if (input.type === 'select') {

            let value = this.props.inputs[input.key];

            if (
                input.key === 'ad_type'
            ) {
                value = this.props.inputs.ads[adIndex].ad_type;
            }

            if ( value || value === 0 ) {

                // before the first handleInputChange, `${value}` is an integer,
                // essentially, an enum

                // after the handleInputChange, `${value}` is an object

                if ( Number.isInteger( value ) || typeof value[0] === 'string' ) {
                    let item = input.options.find( i => i.value === value );
                    value = { value: item.value, label: item.name };
                } else {
                    value = this.props.inputs[input.key];
                }

            }

            let positionKeys = ['account_type','pacing_sheet','report_type','type'];
            let position = 'top';

            for( let i = 0; i < positionKeys.length; i++ ) {
                if ( input.key === positionKeys[i] ) {
                    position = 'auto';
                    break;
                }
            }

            return (
                <div style={{display: 'grid', width: '100%'}}
                     key={ `${input.key}-div` }>{
                    [
                        <InputLabel
                            key={`${input.key}-label`}
                            htmlFor="name-error"
                            margin={'dense'}
                            shrink={true}
                            filled={true}
                            disableAnimation={true}
                        >{
                            input.label
                        }</InputLabel>,
                        <FormControl
                            key={ `${input.key}-control` }
                            fullWidth={true}
                            // classes={{
                            //     root: 'select-input'
                            // }}
                        >
                            <Select
                                styles={SelectStyles}
                                key={`${input.key}-select`}
                                menuPlacement={position}
                                name={input.key}
                                isMulti={false}
                                isSearchable={true}
                                value={ value }
                                onChange={(e) => this.props.handleInputChange(e, input.key, adIndex)}
                                noOptionsMessage={() => {
                                    if( input.options.length ) {
                                        return 'no matches!';
                                    } else {
                                        return 'Loading Client List...';
                                    }
                                } }
                                options={
                                    input.options.map((option) => {
                                        return {
                                            value: option.value,
                                            label: option.name
                                        }
                                    })
                                }
                            />
                        </FormControl>
                    ]
                }</div>
            );
        }

        // CHECKBOX INPUT OPTION

        else if (input.type === 'checkbox') {

            return (
                <FormControlLabel
                    key={i}
                    classes={{
                        root: "checkbox-input"
                    }}
                    control={
                        <Checkbox
                            checked={typeof adIndex !== 'undefined' ? (
                                (this.props.inputs.ads[adIndex][input.key] === 1 || this.props.inputs.ads[adIndex][input.key] === true)
                            ) : (
                                (this.props.inputs[input.key] === 1 || this.props.inputs[input.key] === true)
                            )}
                            onChange={typeof adIndex !== 'undefined' ? (
                                (e) => this.props.handleInputChange(e, input.key, adIndex)
                            ) : (
                                (e) => this.props.handleInputChange(e, input.key)
                            )}
                            color="primary"
                        />
                    }
                    label={input.name}
                />
            );
        }

        // TEXT FIELD DEFAULT INPUT OPTION

        else {
            let value, onChange;
            if (typeof adIndex !== 'undefined') {
                onChange = (e) => this.props.handleInputChange(e, input.key, adIndex);

                // conditional for subcomponents like campaigns, adsets, etc.

                let subComponent;

                if (input.key === 'geo_name') {
                    subComponent = 'geos';
                }
                else if (this.props.adsets) {
                    subComponent = 'adsets';
                }
                else if (this.props.campaigns) {
                    subComponent = 'campaigns';
                }
                else if (this.props.ads) {
                    subComponent = 'ads';
                }

                if (this.props.inputs[subComponent][adIndex][input.key]) {
                    value = this.props.inputs[subComponent][adIndex][input.key];
                }
                else {
                    value = '';
                }
            }
            else {
                onChange = (e) => this.props.handleInputChange(e, input.key);
                // console.log( input, input.key, this.props.inputs );
                let i = '';
                if ( this.props.inputs ) {
                    i = this.props.inputs[input.key];
                }
                value = i;
            }

            let numberProps = {};

            if ( input.type === 'number' ) {

                [ 'min', 'max', 'step' ].forEach( propKey => {
                    if ( propKey in input )
                       numberProps[ propKey ] = input[ propKey ];
                });

            }


            return (
                <TextField
                    inputProps={
                        {
                            ...numberProps,
                            readOnly: (input.type && input.type === 'readonly')
                        }
                    }
                    error={this.props.errors[input.key]}
                    fullWidth={'fullWidth' in input}
                    helperprimary={this.props.errors[input.key] ? input.errorText : null}
                    key={i}
                    id={input.key}
                    label={input.name}
                    classes={{
                        root: classNames("text-input", {
                            short: !('fullWidth' in input)
                        })
                    }}
                    type={input.type}
                    value={ value ? value : input.default }
                    onChange={onChange}
                    margin='dense'
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.props.submit();
                        }
                    }}
                />
            );

        }

    }


    /**
     *
     * @returns {*}
     */
    render() {

        return (

            <Dialog
                classes={{
                    root: 'dialog',
                    paper: 'paper'
                }}
                id={'id' in this.props ? this.props.id : ''}
                open={this.props.open}
                onClose={() => this.props.handleRequestClose()} >

                {/*DIALOG TITLE*/}

                <DialogTitle>
                    {this.props.edit ? (
                        "Edit existing " + this.props.entityName
                    ) : (
                        "Create New " + this.props.entityName
                    )}
                </DialogTitle>

                {/*DIALOG CONTENT*/}

                <DialogContent>
                    {this.props.form.rows.map((row, r) => {

                        return [
                            'label' in row ? (
                                <h6 key="label">{row.label}</h6>
                            ) : (
                                null
                            ),
                            <div key={r} className={classNames('d-flex', 'client-form-row', 'justify-content-left')}>

                                {row.inputs.map((input, i) => {
                                    return this.renderInput(input, i);
                                })}
                            </div>
                        ];
                    })}

                    {/*GEOS SUBCOMPONENT FOR SEIM SCRIPT TEMPLATES*/}

                    {'geos' in this.props ? [
                        <br key='break_one'/>,
                        <br key='break_two'/>,
                        <h4 key='header'>Geos</h4>,
                        this.props.inputs.geos.map((geoNum, a) => {

                            return [
                                <div className="d-flex ad-container" key={a}>
                                    <div className="adHeader">Geo {a+1}</div>
                                    <IconButton
                                        onClick={() => this.props.deleteGeo(a)}
                                        classes={{
                                            root: 'delete-ad'
                                        }} >

                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>,
                                <div key={'ad_'+a} className="d-flex flex-column">
                                    {this.props.geoForm.rows.map((row) => {
                                        return row.inputs.map((input, i) => {
                                            return this.renderInput(input, i, a, true);
                                        });
                                    })}
                                </div>
                            ];
                        }),
                        <Fab
                            size='small'
                            key="add"
                            onClick={() => this.props.newGeo()}
                            classes={{
                                root: 'add-button'
                            }} >

                            <Icon>add</Icon>
                        </Fab>
                    ] : (
                        null
                    )}

                    {/*ADS SUBCOMPONENT FOR SEIM SCRIPT TEMPLATES*/}

                    {'ads' in this.props ? [
                        <br key='break_one'/>,
                        <br key='break_two'/>,
                        <br key='break_three'/>,
                        <br key='break_four'/>,
                        <h4 key='header'>Ads</h4>,
                        this.props.inputs.ads.map((adNum, a) => {
                            return [
                                <div className="d-flex ad-container" key={a}>
                                    <div className="adHeader">Ad {a+1}</div>
                                    <IconButton
                                        onClick={() => this.props.deleteAd(a)}
                                        classes={{
                                            root: 'delete-ad'
                                        }} >

                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>,
                                <div key={'ad_'+a} className="d-flex flex-column">
                                    {this.props.adForm.rows.map((row) => {
                                        return row.inputs.map((input, i) => {

                                            const isHeadline = input.key.includes('headline_part');
                                            const isDescription = input.key.includes('description_part');

                                            const num = +(input.key.match(/\d+/g)??[].join(''));

                                            const banned = (
                                                (
                                                    isHeadline
                                                    || isDescription
                                                )
                                                && (
                                                    adNum.ad_type === 'ETA'
                                                    || (
                                                        adNum.ad_type.value ?? ''
                                                    ) === 'ETA'
                                                )
                                                && (
                                                    (
                                                        isHeadline
                                                        && num > 3
                                                    )
                                                    || (
                                                        isDescription
                                                        && num > 2
                                                    )
                                                )
                                            );

                                            return !banned ? this.renderInput(input, i, a, true) : null;

                                        });
                                    })}
                                </div>
                            ];
                        }),
                        <Fab
                            key="add"
                            size='small'
                            onClick={() => this.props.newAd()}
                            classes={{
                                root: 'add-button'
                            }} >

                            <Icon>add</Icon>
                        </Fab>
                    ] : (
                        null
                    )}

                    {/*CAMPAIGNS SUBCOMPONENT FOR PACING ROWS*/}

                    {this.props.campaigns ? [
                        <br key="b1"/>,<br key="b2"/>,
                        <h5 key="campaigns">Campaigns (not case sensitive)</h5>,
                        this.props.inputs.campaigns.map((campaignNum, a) => {
                            return [
                                <div className="d-flex ad-container" key={a}>
                                    <div className="adHeader">Campaign {a+1}</div>
                                    <IconButton
                                        onClick={() => this.props.deleteCampaign(a)}
                                        classes={{
                                            root: 'delete-ad'
                                        }} >

                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>,
                                <div key={'ad_'+a} className="d-flex flex-column">
                                    {this.props.campaignForm.rows.map((row) => {
                                        return row.inputs.map((input, i) => {
                                            return this.renderInput(input, i, a);
                                        });
                                    })}
                                </div>
                            ];
                        }),
                        <Fab
                            size='small'
                            key="add"
                            onClick={() => this.props.newCampaign()}
                            classes={{
                                root: 'add-button'
                            }} >

                            <Icon>add</Icon>
                        </Fab>
                    ] : (
                        null
                    )}

                    {/*ADSET-IDS SUB COMPONENT FOR PACING ROWS*/}

                    {this.props.adsets ? [
                        <br key="b1"/>,<br key="b2"/>,
                        <h5 key="adsets">Campaign ID</h5>,
                        this.props.inputs.adsets.map((adsetNum, a) => {
                            return [
                                <div className="d-flex ad-container" key={a}>
                                    <div className="adHeader">Campaign ID {a+1}</div>
                                    <IconButton
                                        onClick={() => this.props.deleteAdSet(a)}
                                        classes={{
                                            root: 'delete-ad'
                                        }} >

                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>,
                                <div key={'ad_'+a} className="d-flex flex-column">
                                    {this.props.adsetForm.rows.map((row) => {
                                        return row.inputs.map((input, i) => {
                                            return this.renderInput(input, i, a);
                                        });
                                    })}
                                </div>
                            ];
                        }),
                        <Fab
                            size='small'
                            key="add"
                            onClick={() => this.props.newAdSet()}
                            classes={{
                                root: 'add-button'
                            }} >

                            <Icon>add</Icon>
                        </Fab>
                    ] : (
                        null
                    )}
                </DialogContent>

                {/*CANCEL AND SAVE BUTTONS*/}

                <DialogActions>
                    <Button onClick={() => this.props.handleRequestClose()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => this.props.submit()} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FormDialog;