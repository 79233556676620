import React from 'react';
import Content from './Content';
import Header from "./../Header";
import RouteTree from './Routes';

import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    withRouter
} from "react-router-dom";

class ManagementHome extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            open: false
        };
    }


    /**
     *
     * @param loading
     */
    setLoading(loading) {
        this.setState({
            loading: loading
        })
    }


    /**
     *
     * @param event
     * @param reason
     */
    handleSnackClose(event, reason) {

        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            open: false,
            errorData: {
                status: '',
                message: '',
                line: '',
                file: ''
            }
        });
    }

    renderRoute(props, route)
    {


        const path = props.location.pathname.split('/')[2];
        const hasSubSection = route.subSections.filter(({path:p})=>p.includes(path));

        if(
            (route.subSections
            && !(hasSubSection?.length))
            || !path
        ) {

            const to = `/${route.name}${route.subSections[0].path}`;
            // console.log(
            //     'redirect!', to
            // );
            return <Redirect
                to={to}
            />;

        }

        // console.log('content!', route.name, path);

        return (
            <div id='management'>
                <Header prevRoute={props.location?.pathname} logout={this.props.logout} username={this.props.username} />
                <div key="content" className="management-scroll">
                <Content
                    {
                        ...props
                    }
                    loading={this.state.loading}
                    route={route}
                    API={this.props.API}
                    startLoading={() => this.setLoading(true)}
                    stopLoading={() => this.setLoading(false)}
                    routes={RouteTree}
                />
                </div>
            </div>
        );

        // return null;

    }

    doRedirect() {

        // console.log('here');
        // a fall back route for anything not matched
        // redirect somewhere?
        // try to use prevRoute
        // prevRoute is set if not logged in
        // console.log(this.props.prevRoute);
        // most of the time this  might be /login

        let validRoute = RouteTree.filter(
            r =>
                r.path.includes(
                    (this.props.prevRoute??'').split('/')[1] ?? ''
                )
        )[0];

        let validSubSection = (this.props.prevRoute??'').split('/')?.[2]
            ?? '';

        const hasSubSection = validSubSection.length ? validRoute.subSections.filter(({path:p})=>p.includes(validSubSection)) : null;

        let go = '/config/clients';

        if(validRoute) {
            go = '/' + validRoute.name;
        }

        if(hasSubSection && validRoute) {
            go += '/' + this.props.prevRoute.split('/').slice(2).join('/');
        } else if(validRoute) {
            go += validRoute.subSections[0].path;
        }

        // console.log(go);

        return <Redirect
            key="defaultPath"
            to={go}
        />;
        // return null;
    }

    /**
     *
     * @returns {*[]}
     */
    render() {

        return (
            <Router>
                <Switch>{
                    [
                        ...RouteTree.map(route=>{
                            return <Route
                                key={route.name}
                                path={route.path}
                                render={(props)=>{
                                    // console.log(props, route);
                                    return this.renderRoute(props, route);
                                }}
                            />;

                        }),

                        ...[
                            this.doRedirect()
                        ],

                    ]
                }</Switch>
            </Router>
        );
    }
}

export default withRouter(ManagementHome);