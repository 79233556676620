import React from 'react';
import {
    TextField,
    InputAdornment,
    InputLabel,
    Icon,
    FormControl,
    Button
} from '@mui/material';

import Select from 'react-select';
import SelectStyles from '../../utils/SelectStyles';

class SpeedTest extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            url: '',
            device:  [ { value: 'mobile', label: 'Mobile'} ],
            results: ''
        };
    }


    /**
     *
     */
    getSiteData() {
        if (this.state.url.trim() === '') {
            alert('Please enter a URL');
        }
        else if (this.state.url.indexOf('http') === -1) {
            alert('Please include http in the URL');
        }
        else {
            this.props.startLoading();

            this.props.API({
                service: 'management',
                route: 'speed/request',
                data: {
                    url: this.state.url,
                    device: this.state.device.value
                }
            })
                .then((data) => {
                    this.props.stopLoading();
                    this.setState({
                        results: data
                    });
                });
        }
    }


    /**
     *
     * @returns {*}
     */
    render() {
        return (
            <div id='speed-test'>

                <div
                    className="d-flex flex-column speed-test-header"
                    style={{ maxWidth: '50%'}}
                >

                    <h2>Website Speed Test</h2>

                    <TextField
                        id="search-box"
                        label="Site URL"
                        type="text"
                        value={this.state.url}
                        onChange={(e) => {
                            this.setState({
                                url: e.target.value
                            });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.getSiteData();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment classes={{
                                    root: 'search-icon'
                                }}>
                                    <Icon>search</Icon>
                                </InputAdornment>
                            )
                        }}
                    />

                    <div style={{display: 'grid', width: '25%', marginTop: '20px', marginBottom: '20px' }}>{
                        [
                            <InputLabel
                                htmlFor="name-error"
                                key='device-label'
                                margin={'dense'}
                                shrink={true}
                                filled={true}
                                disableAnimation={true}
                            >
                                Device
                            </InputLabel>,

                            <FormControl
                                key="device-select"
                                fullWidth={true}
                                classes={{
                                    root: 'select-input'
                            }}>
                                <Select
                                    styles={SelectStyles}
                                    key='device-select'
                                    name='device'
                                    isMulti={false}
                                    isSearchable={true}
                                    placeholder='Select Device...'
                                    value={this.state.device}
                                    onChange={ (e) => {
                                        this.setState({
                                            device: e
                                        });
                                    } }
                                    noOptionsMessage={() => {
                                        return 'No Devices!'
                                    }}
                                    options={
                                        [
                                            { value: 'mobile', label: 'Mobile'},
                                            { value: 'desktop', label: 'Desktop'}
                                        ]
                                    }
                                />
                            </FormControl>
                        ]
                    }</div>

                    <Button
                        classes={{
                            root: 'submit'
                        }}
                        onClick={() => this.getSiteData()} variant='contained'
                        style={{
                            marginTop:'20px',
                            display: 'flex',
                            alignSelf: 'flex-start'
                        }}
                    >
                        Submit
                    </Button>

                    <br/>

                    <span dangerouslySetInnerHTML={{__html: this.state.results}} />

                </div>

            </div>
        );
    }
}

export default SpeedTest;