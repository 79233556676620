import React from 'react';
import {
    Paper,
    IconButton,
    Icon
} from '@mui/material';

class Job extends React.Component {

    /**
     *
     * @param id
     */
    deleteJob = (uuid) => {

        this.props.startLoading();
        // console.log(uuid);
        this.props.API({
            service: 'management',
            route: 'jobs/delete/'+uuid
        })
            .then(data => {
                this.props.getJobs();
            });
    };


    /**
     *
     * @param id
     */
    retryJob = (uuid) => {
        // console.log(uuid);
        this.props.startLoading();
        this.props.API({
            service: 'management',
            route: 'jobs/retry/'+uuid
        })
            .then(data => {
                this.props.getJobs();
            });
    };


    /**
     *
     * @returns {*}
     */
    render() {

        let type = this.props.job.displayName.replace('MoranServer\\Jobs\\','').replace('App\\Jobs\\','').replace('Job','');

        let email = null;
        let scraper = null;
        let script = null;
        let platform = null;
        let sheetName = null;
        let clientName = null;
        let pacingRow = null;
        let className = null;
        let dates = null;
        let clients = [];
        let date = null;

        let c = this.props.job.data.command;

        if (type === 'Scraper') {
            scraper = c.scraperMapKey;
        }

        else if (
            type === 'QuickBasePacingRow'
        ) {
            clientName = c.acronym;
            sheetName = c.catTacString;
            platform = c.monthYearString;
        }
        else if(
            type.includes('NinjaCatCustomSourcesWriteToSheet')
        ) {

            type = 'NC Custom Sources Write Sheet';
            platform = c.source;

        }
        else if(
            type.includes('NinjaCatCustomSources')
        ) {

            type = 'NC Custom Sources';
            clientName = c.acronyms.join(', ').trim();
            dates = c.dates;
            platform = c.source;

        } else if(
            type.includes('QuickBasePacingCustomSources')
        ) {

            type = 'QB Custom Sources';
            clientName = c.acronyms.join(', ').trim();
            dates = c.dates;
            platform = c.source;

        }
        else if(
            type.includes('VlaToSql')
        ) {
            dates = [c.day];
        }
        else if (
            type.includes('NinjaCatRun')
        ) {

            type = type.includes('Fetch')
                ? 'NC/QB Fetch'
                : 'NC/QB Run';

            sheetName = c.templateName;
            clientName = c.externalId ? c.externalId : c.advertiserId;
            dates = c.dates;
            // dates = c.dates.reverse();

        }
        else if(
            type.includes('VehiclePricesCleanup')
        ) {
            clientName = c.acronym;
            dates = [c.day];
        }
        else if (
            type.includes('NinjaCatFetch')
        ) {

            type = type.includes('Fetch')
                ? 'NC/QB Fetch'
                : 'NC/QB Run';

            clientName = c.acronym;
            platform = c.som;

        }
        else if ('clientName' in c) {
            clientName = c.clientName;

            if ('className' in c) {
                className = c.className.replace('App\\Console\\Commands\\','');
                dates = c.dates;
            }
        }
        else if (type === 'BurmaFinish') {
            clientName = c.folderName;
        }
        else if (type === 'Burma') {
            clientName = c.clientName;
            platform = c.catTac;
        }
        else if (type === 'SEIMFeed') {
            scraper = c.seimFeedTab;
        } else if(type === 'DynamicInventory') {
            scraper = c.tab;
        }
        else if (type === 'SEIMScript') {
            clientName = c.jobDetails?.campaignName ?? '';
            script = c.jobDetails.tab;
            platform = c.platform;
            sheetName = c.jobDetails.scriptId;
        }
        else if (type === 'SEIMAdParams') {
            type = 'SEIMAdParamsTest';
            script = c.tab;
            platform = c.campaign;
        } else if (
            type === 'QuickBaseCampaignMetricsMatchBudgets'
        ) {
            type="QBBudgetMatch";
            clientName=c.budgetId;
        } else if (
            type === 'AutomatedReportingNotes'
        ) {
            type = "ReportingNotes";
            clientName = c.acronym;
            platform = c.catTacId;
            dates = [
                c.flightStart,
                c.flightEnd
            ];

        } else if (
            type === 'DocsAutomatedReportingNotes'
        ) {
            type = "ReportingDocsNotes";
            clientName = c.acronym;
            date = c.currMonthInt;
            platform = c.test ? 'test only' : 'uploading to doc'
        } else if (
            type === 'QuickBaseBudgetCrossCheck'
        ) {

            type = "QB Cross Check";
            dates = [
                c.flightStart,
                c.flightEnd
            ];
            clientName = c.acronym;
            platform = c.platformName;

        }

        return (
            <Paper
                classes={{
                    root: "d-flex flex-column job"
                }} >

                <div className="d-flex justify-content-between">
                    <h5>{type}</h5>
                    <div>
                    {'exception' in this.props.job ? (
                        <IconButton
                            variant='contained'
                            onClick={() => this.retryJob(this.props.job.uuid)}
                            classes={{
                                root: 'job-delete'
                            }} >

                            <Icon>autorenew</Icon>
                        </IconButton>
                    ) : (
                        null
                    )}
                    {'exception' in this.props.job ? (
                        <IconButton
                            variant='contained'
                            onClick={() => this.deleteJob(this.props.job.uuid)}
                            classes={{
                                root: 'job-delete'
                            }} >

                            <Icon>delete</Icon>
                        </IconButton>
                    ) : (
                        null
                    )}
                    </div>
                </div>

                {scraper ? (
                    <h6>{scraper}</h6>
                ) : (
                    null
                )}

                {script ? (
                    <h6>{script}</h6>
                ) : (
                    null
                )}

                {pacingRow ? (
                    <h6>{pacingRow}</h6>
                ) : (
                    null
                )}

                {className ? (
                    <h6>{className}</h6>
                ) : (
                    null
                )}

                {dates ? (
                    <h6>{dates[dates.length-1]+' to '+dates[0]}</h6>
                ) : (
                    null
                )}

                {platform ? (
                    <h6>{platform}</h6>
                ) : (
                    null
                )}

                {email ? (
                    <h6>{email}</h6>
                ) : (
                    null
                )}

                {sheetName ? (
                    <h6>{sheetName}</h6>
                ) : (
                    null
                )}

                {clientName ? (
                    <h6>{clientName}</h6>
                ) : (
                    null
                )}

                {date ? (
                    <h6>{date}</h6>
                ) : (
                    null
                )}

                {clients.length > 0 ? (
                    clients.map((client,c) => {
                        return <span key={c}>{client}</span>;
                    })
                ) : (
                    null
                )}

                {'exception' in this.props.job ? (
                    <details>
                        <summary>Exception</summary>
                        {this.props.job.exception}
                    </details>
                ) : (
                    null
                )}

            </Paper>
        );
    }
}

export default Job;