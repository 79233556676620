import classNames from "classnames";
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

class Header extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        // console.log('header', props.prevRoute);
        this.state = {
            welcomeDropdown: false
        }
    }


    /**
     *
     * @returns {*}
     */
    render() {

        return (
            <header className={classNames('d-flex','justify-content-between', 'header')} id='admin-header'>

                {/*BRAND LOGO*/}
                <Link to="/" className={classNames('d-flex','align-items-center')}>
                    <img id="header-logo" src={window.location.protocol+'//'+window.location.hostname+"/images/logo.png"} alt="The Fuel Gauge Logo"/>
                </Link>

                {/*WELCOME DROPDOWN AND LOGOUT BUTTON*/}
                <div id="welcome" className={classNames('d-flex','align-items-center')}>

                    {/*DESKTOP MENU*/}
                    <div id="welcome-text" onClick={() => this.setState({ welcomeDropdown: !this.state.welcomeDropdown })}>
                        <span>{'Welcome '+this.props.username}</span>
                        <FontAwesomeIcon icon="caret-down" />
                    </div>

                    {/*MOBILE MENU*/}
                    <div id='mobile-welcome-button' onClick={() => this.setState({ welcomeDropdown: !this.state.welcomeDropdown })}>
                        <FontAwesomeIcon icon="bars"/>
                    </div>

                    {/*DROPDOWN*/}
                    {this.state.welcomeDropdown ? (
                        <div key='dropdown' id='dropdown-background' onClick={() => this.setState({ welcomeDropdown: false })}>
                            <div id='dropdown'>
                                <ul>
                                    <Link to='/login' onClick={()=>this.props.logout(this.props.prevRoute)}>
                                        <li className={classNames('d-flex','justify-content-center','align-items-center')}>
                                            <FontAwesomeIcon icon="sign-out-alt" />
                                            <span>Logout</span>
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </div>

            </header>
        );
    }
}

Header.propTypes = {
    logout: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired
};

export default Header;