import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';

class FormattedInput extends PureComponent {

    render() {
        let {inputRef, onChange, ...props} = this.props;

        return (
            <NumberFormat
                {...props}
                onValueChange={(values) => {

                    this.props.onChange({
                        target: {
                            value: values.floatValue,
                        },
                    });
                }}
                thousandSeparator
                prefix={this.props['data-currency'] ? "$" : ''}
                suffix={this.props['data-percent'] ? "%" : ''}
            />
        );
    }
}

export default FormattedInput;
