import React from 'react';
import Clients from './Clients';
import SEIM from './SEIM';

const components = {
    SEIM: SEIM,
    Clients: Clients,
};

class Settings extends React.Component {

    render() {
        let { subSection } = this.props;
        subSection = subSection ? subSection.replace(/[ |\s]/g, '') : '';
        const Section = subSection
            ? components[ subSection ]
            : null;
        return Section ? <Section {...this.props} /> : null;
    }
}

export default Settings;