import React, {Component} from 'react';
import {withCookies} from 'react-cookie';
import ManagementHome from "./management/ManagementHome";
import Login from "./Login";
import JSONbig from 'json-bigint';
import moment from "moment-timezone";
import './styles/app.scss';
import Loading from './utils/Loading';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';


class App extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {

        super(props);

        this.isPuppBrowser = navigator.userAgent === 'pupp';
        this.isPupp = this.isPuppBrowser || navigator.userAgent === 'pupp2';

        // you can use this to preview as chrome emulated device, from top-right dev tools, toggle device toolbar, then click chrome emulated device in settings
        // setup a device 1350 x 1747 = parseInt((1350x(11/8.5))
        // with userAgeent string of pupp2
        // the paths change below if you're actually puppeteer, not if you're just previewing

        this.timezone = moment.tz.guess();
        this.environment = window.location.port === '3000' ? 'CREATE_REACT_APP' : 'KOA';

        this.tokenPath = this.isPuppBrowser ? window.location.protocol+'//frontend:3000/' : window.location.port === '3000' ? window.location.protocol+'//tfg-dash.test/' : '/';
        this.apiPath = this.isPuppBrowser ? window.location.protocol+'//api:3000' : this.tokenPath + 'api';

        this.csrfResubmissionCounts = {};

        this.state = {
            loggedIn: typeof props.cookies.get('tfg_jwt') !== 'undefined',
            JWT: 'Bearer '+ (props.cookies.get('tfg_jwt') || ''),
            username: this.props.cookies.get('tfg_un'),
            CSRF: this.environment === 'CREATE_REACT_APP' ? true : '',
            admin: false,
            permissions: [],
            prevRoute: null
        };

    }

    // componentWillUnmount() {

    // }

    componentDidUpdate(prevProps, prevState) {

        if(this.state.loggedIn !== prevState.loggedIn) {
            this.doLoginColors();
        }

    }


    doLoginColors() {

        const $d = document.documentElement;
        // const altColor = getComputedStyle($d).getPropertyValue('--altBackground');
        // const mainColor = getComputedStyle($d).getPropertyValue('--mainBackground');

        // console.log(a);
        // console.log($d);
        $d.style.setProperty(
            'background',
            !this.state.loggedIn
                ? 'var(--altBackground)'
                : 'var(--mainBackground)'
        );
    }

    /**
     *
     */
    componentDidMount = () => {

        this.doLoginColors();


        this.fetchCsrfToken()
            .then(this.fetchPermissionsForUser)
            .catch( (err) => {

                if ( err && 'message' in err && err.message.trim() !== 'The operation was aborted.' ) {
                    // black hole
                } else {
                    console.warn('fetch token error');
                }

            })
    };


    /**
     * make call to '/' path on domain to get CSRF token from server
     */
    fetchCsrfToken = () => {

        return fetch( this.tokenPath, {
            method: 'GET',
        })
            .then(res => {

                // console.log(res.headers.get('X-CSRF-TOKEN'));

                this.setState({
                    CSRF: res.headers.get('X-CSRF-TOKEN')
                });

            })
            .catch(err=>{console.log('error', err)})
    };


    /**
     * get permissions for active user
     */
    fetchPermissionsForUser = () => {

        return this.API({
            service: 'users',
            method: 'getPermissionsForUser',
        })
            .then((data) => {

                if (data) {
                    this.setState({
                        permissions: data.permissions,
                        admin: data.admin
                    });
                } else {
                    // console.warn('no data');
                }

            })
            .catch(err=>{console.log('error', err)})
    };


    /**
     *
     * @param authData
     */
    setAuthorizedUser = (authData) => {

        // set cookies
        this.props.cookies.set('tfg_jwt', authData.jwt, { path: '/' });
        this.props.cookies.set('tfg_un', authData.username, { path: '/' });

        // store JWT token and mark as logged in
        this.setState({
            JWT: authData.jwt,
            username: authData.username,
            loggedIn: true
        }, () => {

            this.fetchPermissionsForUser()
                .catch( (err) => {
s
                    if ( err && 'message' in err && err.message.trim() !== 'The operation was aborted.' ) {
                        // black hole
                    } else {
                        console.warn('fetch token error');
                    }

                })

        });
    };


    /**
     *
     */
    logout = (path) => {
        // remove cookies
        this.props.cookies.remove('tfg_jwt', { path: '/' });
        this.props.cookies.remove('tfg_un', { path: '/' });
        // console.log('logout!', path);

        this.setState({
            JWT: null,
            username: null,
            admin: false,
            permissions: [],
            loggedIn: false,
            prevRoute: path,
        });

    };

    /**
     *
     * @param requestDetails
     * @returns {Promise<ArrayBuffer>}
     * @constructor
     */
    API = async ( requestDetails ) => {

        try {

            let isPDF = requestDetails.service === 'pdf-page';

            const requestObj = {
                method: 'POST',
                body: JSON.stringify( requestDetails ),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.JWT,
                    'X-CSRF-TOKEN': this.state.CSRF,
                    'Time-Zone': this.timezone
                }
            };

            // console.log(
            //     this.apiPath,
            //     requestDetails,
            //     requestObj.headers,
            //     this.state.CSRF
            // );

            if ( requestDetails.signal ) {
                requestObj.signal = requestDetails.signal;
            }

            // console.log(requestObj);

            const res = await fetch( this.apiPath, requestObj );

            // if 200 response, return JSON from response
                // console.log(res);
                if (res.ok && isPDF) {

                    return res.json().then((file) => {
                        let bufferFile = new Buffer.from(file.data);
                        return new Blob([bufferFile], {type: 'application/pdf'});
                    });

                } else if (res.ok) {

                    const text = await res.text();

                    if (text) {
                        try {

                            setTimeout(() => {
                                if (this.isPupp && typeof window.doPageNumbers === 'function') {
                                    window.doPageNumbers();
                                }
                            }, 200);

                            return JSONbig.parse(text);

                        } catch (err) {
                            if (err.message.indexOf('Unexpected') > -1) {
                                return text;
                            } else throw err;
                        }
                    }
                }

                // if request is UNAUTHORIZED clear tokens and username from cookies
                // and redirect to login
                else if (res.status === 401) {

                    // remove cookies
                    this.props.cookies.remove('tfg_jwt', {path: '/'});
                    this.props.cookies.remove('tfg_un', {path: '/'});

                    this.setState({
                        loggedIn: false,
                        JWT: '',
                        username: null
                    });

                    // this.props.history.push('/login');

                }

                // if CSRF is invalid send request to obtain new CSRF token for new sessions
                // and resubmit request
                //
                // why the aikido?
                //
                // because if the server is restarted, for some strange reason, mid page load with many request being sent back,
                // we need to fetch to get the csrf token several times until we finally get the most up-to-date and correct token
                // solving this by recursing and resubmitting after every 403 response until 200, but making sure not to start an endless loop
                else if (res.status === 403) {

                    // counts of resubmissions to avoid memory leak
                    const resubmissionCountKey = JSON.stringify(requestDetails);
                    if (!(resubmissionCountKey in this.csrfResubmissionCounts)) {
                        this.csrfResubmissionCounts[resubmissionCountKey] = 0;
                    }

                    // fetch new CSRF token and recurse, resubmitting request
                    return this.fetchCsrfToken()
                        .then(_ => {

                            this.csrfResubmissionCounts[resubmissionCountKey]++;

                            // resubmit max 5 times per request, looking for that up-to-date CSRF each time, to avoid memory leak
                            if (this.csrfResubmissionCounts[resubmissionCountKey] < 5) {
                                return this.API(requestDetails);
                            }
                        });
                }

                // catch all other error status codes (500, etc)
                // and throw error
                else throw new Error(await res.text());

        }
        catch (err) {

            // GTM will sometimes not update the tag correctly,
            // make sure the user knows that this happened
            // for everything else, quietly log to console
            if ( requestDetails && 'route' in requestDetails && requestDetails.route.indexOf('gtm-client') > -1 ) {
                alert(err.message);
            }
            else {

                // for users form validation in dash admin UI, we need the error message
                if (requestDetails && 'service' in requestDetails && requestDetails.service === 'users') {
                    return err.message;
                } else {

                    if( err.message.trim() !== 'The operation was aborted.') {
                        console.log('error', err );
                    }

                }

            }
        }

    };

    /**
     *
     * @returns {*}
     */
    render() {

        // this is just app waiting for the CSRF token so that login request does not fail

        if ( this.state.CSRF || this.isPupp ) {

            // APP LOGIN
            if ( !this.state.permissions || !this.state.loggedIn || !this.state.username || this.state.username === 'undefined' ) {
                // console.log(process.env);
                return (

                    <Switch>

                        <Route key="login" exact path="/login" render={() => {
                            return <Login
                                isLoggedIn={this.state.loggedIn ?? null}
                                API={this.API} setAuthorizedUser={this.setAuthorizedUser}
                            />
                        }} />

                        <Route key="login" exact path="*" render={ props => {

                            if(this.state.prevRoute!==props.location?.pathname)
                                this.setState({
                                    prevRoute: props.location?.pathname
                                });

                            return <Redirect to='/login' />;

                        }} />

                    </Switch>

                );

            }
            else {

                // MANAGEMENT PANEL
                if (
                    window.location.hostname.split('.').length > 2 &&
                    window.location.hostname.split('.').indexOf('management') > -1
                ) {
                    // console.log('here');
                    return <ManagementHome prevRoute={this.state.prevRoute} API={this.API} logout={this.logout} username={this.state.username}/>;
                }

            }

        }
        else {
            // console.log(this.state.CSRF, this.isPupp)
            return (<Loading/>);
        }
    }

}
export default withCookies(withRouter(App));
