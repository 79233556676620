import React from 'react';
import classNames from 'classnames';
import {
    List,
    ListItem,
    ListItemText,
    Button
} from '@mui/material';

import {
    Link
} from 'react-router-dom';

class SideBar extends React.Component {

        /**
     *
     * @param props
     */
         constructor(props) {
            super(props);
        }
    /**
     *
     */
    clearJobs() {

        this.props.startLoading();

        this.props.API({
            service: 'management',
            route: 'jobs/clear'
        })
            .then(data => {
                this.props.stopLoading();
            });
    }

    /**
     *
     */
    retryFailedJobs() {

        this.props.API({
            service: 'management',
            route: 'jobs/retry-all'
        })
            .then(data => {
                this.props.stopLoading();
            });
    }


    /**
     *
     * @returns {*}
     */
    render() {
        return (
            <div id="side-nav">
                <List
                    classes={{
                        root: 'side-nav-list'
                    }}
                >
                    {this.props.subSections.map(({label, path}, i) => {

                        const c = <ListItem
                            button
                            classes={{
                                root: classNames('sub-section', {
                                    active: label === this.props.subSection.label
                                })
                            }}
                        >
                            <ListItemText
                                primary={label}
                                classes={{
                                    primary: 'list-text'
                                }}
                            />
                        </ListItem>;
                        // return <div/>
                        return ([

                            (
                                <Link
                                    key={i}
                                    to={`/${this.props.selected}${path}`}
                                >{c}</Link>
                            ),

                            // <Divider key={"d"+i} />
                        ]);
                    })}
                </List>
                {this.props.selected === 'jobs' ? [

                    <Button
                        key="clear"
                        classes={{
                            root: 'clearJobsButton'
                        }}
                        onClick={() => this.clearJobs()} variant='contained' >

                        Clear Jobs
                    </Button>,

                    <Button
                        key="retry-failed"
                        classes={{
                            root: 'retryAllJobsButton'
                        }}
                        onClick={() => this.retryFailedJobs()} variant='contained' >

                        Retry all failed
                    </Button>

                ] : null }
            </div>
        );
    }
}

export default SideBar;